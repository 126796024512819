import { useEffect, useState } from 'react'
import { config } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'

export const useComplaint = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {
        fetchAll,
        remove,
        update,
    } = useService('Data', 'complaint')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        showAlert,
        closeModal,
        openModal,
        getFormattedDate,
    } = useService('Misc')
    const filterTags = require('../filterTags.json')
    const complaintOptions = filterTags
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [checkedList, setcheckedList] = useState([])
    const [checkedAll, setcheckedAll] = useState(false)
    const [tableData, settableData] = useState([])
    const [uid, setuid] = useState('')
    const [note, setnote] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [activeStatusIndex, setactiveStatusIndex] = useState(0)
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const [number, setnumber] = useState('')
    const [complaintType, setcomplaintType] = useState('')
    const [orderId, setorderId] = useState('')
    const [status, setstatus] = useState(complaintOptions[0].value)
    const [productName, setproductName] = useState('')
    const [name, setname] = useState('')
    const [activeItem, setactiveItem] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Complaints', 'admin')
        getComplaintsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getComplaintsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No complaints made yet!' })
            } else {
                const tableData = result.data.reverse()
                settableData(tableData)
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1) {
                        activefilterValue = e.value
                    }
                })
                filterItems(activefilterValue, activeFilterIndex, tableData)
                setactiveStatusIndex(complaintOptions.findIndex(e => e.value === tableData.status))
            }
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashComplaint = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getComplaintsData()
            showAlert({ type: 'success', msg: 'Complaint deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete complaint!' })
    }
    const addNote = async (e) => {
        e.preventDefault()
        activeItem.status = status
        const query = {uid: activeItem.uid}
        let complaintNotes = typeof activeItem.complaintNotes === 'undefined'? []: activeItem.complaintNotes
        complaintNotes.push({
            status,
            note,
            timing: new Date().getTime()
        })
        activeItem.complaintNotes = complaintNotes
        delete activeItem._id
        let data = {
            query: JSON.stringify(query),
            complaintData: JSON.stringify(activeItem)
        }
        const result = await update(data)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Complaint updated successfully!' })
            setnote('')
            setstatus('')
            getComplaintsData(query)
            closeModal("complaint-modal")
        } else showAlert({ type: 'error', msg: 'Unable to update Complaint!' })
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }

    const closeComplaintModal = () => {
        setorderId('')
        setname('')
        setemail('')
        setmessage('')
        setnumber('')
        setcomplaintType('')
        setuid('')
        closeModal("complaint-modal")
    }

    const viewItem = (item) => {
        setcomplaintType(item.complaintType)
        setuid(item.uid)
        setnumber(item.number)
        setemail(item.email)
        setmessage(item.message)
        setname(item.name)
        setorderId(item.orderId)
        setproductName(item.productName)
        setactiveItem(item)
        openModal("complaint-modal")
    }

    const trashItem = item => {
        setcomplaintType(item.complaintType)
        setuid(item.uid)
        setnumber(item.number)
        setemail(item.email)
        setmessage(item.message)
        setname(item.name)
        setorderId(item.orderId)
        setproductName(item.productName)
        setactiveItem(item)
        trashComplaint()
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                uid: '#'+item.uid,
                name: item.name,
                number: item.number,
                date: getFormattedDate(new Date(item.date)),
                badge: <Badge item={item.status} />,
                complaintType: item.complaintType,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor onClick={() => viewItem(item)} type='view' />
                    <Actor onClick={() => trashItem(item)} type='trash' />
                </div>
            }
            data.push(e)
        })
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Id',
                    key: 'id'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Mobile Number',
                    key: 'number'
                },
                {
                    label: 'Filing Date',
                    key: 'date'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Type',
                    key: 'type'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])

    let activeStatus = complaintOptions[activeStatusIndex]
    complaintOptions.forEach(e => {
        if (e.value === status)
            activeStatus = e
    })
    return {
        trackScrolling,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        tableContent,
        activeShownTotal,
        trashComplaint,
        trashCancel,
        showTrashConfirm,
        closeComplaintModal,
        orderId,
        uid,
        name,
        number,
        email,
        complaintType,
        productName,
        message,
        activeItem,
        addNote,
        activeStatus,
        setstatus,
        complaintOptions,
        note,
        setnote,
    }
}