import { useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'

export const useServiceOrders = () => {
    const Img = useElement('Img')
    const {getLoggedInUserData} = useService('User')
    const user = getLoggedInUserData()
    const {fetchByAttr} = useService('Data', 'serviceWork')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        getFranchiseData,
        showAlert,
        toIndianCurrency,
        getFormattedDate,
        openModal,
        parseAddress,
    } = useService('Misc')
    const {
        getOrder,
        getOrders,
    } = useService('Order')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const [workData, setworkData] = useState([])
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [tableData, settableData] = useState([])
    const [endDate, setendDate] = useState('')
    const [startDate, setstartDate] = useState('')
    const [searchKey, setsearchKey] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const [serviceWorkAwaitedStatus, setserviceWorkAwaitedStatus] = useState(false)
    const [serviceData, setserviceData] = useState('')
    const [servicework, setservicework] = useState(null)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
            showMore()
        }
    }

    useEffect(() => {
        setTitle('Service Orders', 'admin')
        getOrdersData()
    }, [])

    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getOrdersData = async () => {
        let result = ''
        if(['franchiseOwner', 'franchiseManager'].includes(user?.role)) {
            let query = {
                source: 'franchise',
                franchiseId: getFranchiseData().value
            }
            result = await getOrder(query)
        } else {
            result = await getOrders()
        }
        if (result.status) {
            let tableData = [];
            result.data.reverse().forEach((item) => {
                if (item.status !== 'initialised' && item.status !== 'payment-fail') {
                    let orderData = JSON.parse(item.orderData)
                    orderData.cartData.forEach(e => {
                        if (e.type === 'service') {
                            e.orderData = item
                            tableData.push(e)
                        }
                    })
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex + 1)
                    activefilterValue = e.value
            })
            filterItems(activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No order placed yet!' })
        } else showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let orderTime = item.orderData.timing,
                startTime = startDate === '' ? '' : new Date(startDate),
                endTime = endDate === '' ? new Date() : new Date(endDate)
            if (orderTime >= startTime && orderTime <= endTime) {
                let e = {
                    image: <div className="product-thumbnail">
                        {
                            item.type === 'servicePackage' ?
                            <i className="hi-dropbox service-package-icon"></i>:
                                item.type === 'subscription' ?
                                <i className="hi-block service-package-icon"></i>:
                                    <Img zoom={true} src={item.image} alt={item.title} />
                        }
                    </div>,
                    title: <div className="product-name" data-title="Product">
                        {item.title}
                        <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                    </div>,
                    price: <div className="product-price" data-title="Price">
                        <span className="Price-amount amount">{toIndianCurrency(item.price)}</span>
                    </div>,
                    orderId: <>
                        #{item.orderData.uid}
                    </>,
                    userName: JSON.parse(item.orderData.userData).name,
                    timing: <>
                        {getFormattedDate(item.orderData.timing)}
                        <p className="status-btn" onClick={() => getCompletedWorkData(item.tempUid)}>Status</p>
                    </>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const getCompletedWorkData = async tempUid => {
        let query = {
            tempUid: String(tempUid)
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length > 0) {
                setserviceWorkAwaitedStatus(false)
                setservicework(result.data[result.data?.length - 1])
            } else {
                setserviceWorkAwaitedStatus(true)
            }
        }
        openModal("car-service-status-modal")
    }
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let filteredList = [], tempTitle = ''
            tableData.forEach(e => {
                console.log('e', e)
                tempTitle = (JSON.parse(e.orderData.userData).name).toLowerCase()
                if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
            setactiveFilterIndex(0)
        }
    }

    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    useEffect(() => {
        let workData = null, exportedData = [], cartTotalTax = 0
        filteredList.forEach(e => {
            let orderData = e.orderData
            if (!orderData.discount) {
                orderData.discount = 0
            }
            if (typeof orderData.cartTotal !== "number")
                orderData.cartTotal = Number(orderData.cartTotal)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
            cartTotalTax = 0
            let tmpCartData = orderData.cartData ?? []
            tmpCartData.forEach(( c, i ) => {
                c.tax = Number(c.tax);
                c.price = Number(c.price).toFixed(2)
                c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
                cartTotalTax += c.taxAmount
                if (c.type === 'service' && e.status !== 'initialised' && e.status !== 'payment-fail') {
                    let ex = {
                        Customer_Id: (i === 0) ? JSON.parse(e.userData).uid: '',
                        Order_Id: (i === 0) ? e.uid: '',
                        Customer_Name: (i === 0) ? JSON.parse(e.userData).name: '',
                        Mobile_No: (i === 0) ? JSON.parse(e.userData).mobile: '',
                        Address: (e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? parseAddress(JSON.parse(e.activeAddress)): ''): '',
                        Pin_Code: (e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
                        Status: (i === 0) ? e.status: '',
                        Vehicle_Details: c.activeCar ? c.activeCar.name + ' ' + c.activeCar.Segment: '',
                        Vehicle_No: c.activeCar ? c.activeCar.registrationNo ?c.activeCar.registrationNo: '': '',
                        Service: c.type === 'service'?c.title: '',
                        Total: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal).toFixed(2): ''): '',
                        Discount: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?Number((orderData.discount).toFixed(2)): ''): '',
                        Net_Payment: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal - Number((orderData.discount).toFixed(2))).toFixed(2): ''): '',
                        GST: ( e.status === "initialised") ? '': (i === 0) ? cartTotalTax: ''
                    }
                    exportedData.push(ex)
                }
            })
        })
        setexportedData(exportedData)
        if (servicework !== null) {
            serviceData = JSON.parse(servicework.activeCar)
            if (typeof workData !== 'undefined')
                workData = JSON.parse(servicework.workData)
        }
        settableContent({
            headers: [
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, startDate, endDate])
    return {
        trackScrolling,
        filteredList,
        exportedData,
        setstartDate,
        setendDate,
        startDate,
        endDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        serviceWorkAwaitedStatus,
        serviceData,
        servicework,
        workData,
    }
}