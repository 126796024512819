import { useContext, useEffect, useState } from "react"
import { b2bService, fuelOption, config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useCarListAdmin = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const {
        remove,
        save,
    } = useService/('Data', 'media')
    const {save: saveRecommendation} = useService('Data', 'recommendation')
    const {save: saveBusinessWork} = useService('Data', 'businessWork')
    const {setTitle} = useService('Meta')
    const {getServices} = useService('Service')
    const {
        showPreloader,
        hidePreloader,
        showAlert,
        openModal,
        generateUID,
        closeModal,
        getBase64,
    } = useService('Misc')
    const {getMicroServices} = useService('MicroService')
    const {isValidJson} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getCars,
        getAllCars,
    } = useService('Car')
    const {getProducts} = useService('Product')
    const {commonPageSize} = config
    const [exportedData, setexportedData] = useState([])
    const [tableContent, settableContent] = useState({})
    const [fuelType, setfuelType] = useState(fuelOption[0])
    const [fuelQuantity, setfuelQuantity] = useState('')
    const [activeItem, setactiveItem] = useState(null)
    const [searchKey, setsearchKey] = useState('')
    const [filteredWorkPostList, setfilteredWorkPostList] = useState([])
    const [workPostList, setworkPostList] = useState(b2bService)
    const [activeAction, setactiveAction] = useState('')
    const [uploadCount, setuploadCount] = useState(0)
    const [carImages, setcarImages] = useState([])
    const [title, settitle] = useState('All Cars')
    const [displayedRecommendationsList, setdisplayedRecommendationsList] = useState([])
    const [searchPostKey, setsearchPostKey] = useState('')
    const [filteredPostList, setfilteredPostList] = useState([])
    const [fileObj, setfileObj] = useState([])
    const [file, setfile] = useState([])
    const [fileArray, setfileArray] = useState([])
    const [comments, setcomments] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [owner, setowner] = useState(null)
    const [user, setuser] = useState([])
    const [activeCar, setactiveCar] = useState(null)
    const [tableData, settableData] = useState([])
    const [image, setimage] = useState('')
    const [filteredList, setfilteredList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [recommendationList, setrecommendationList] = useState([])
    const [activeOwnerId, setactiveOwnerId] = useState(false)
    const [companyId, setcompanyId] = useState('')

    useEffect(() => {
        setTitle('Car List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[4]) {
            getCarsData(urlData[4])
            setcompanyId(urlData[4])
        } else {
            getCarsData()
        }
        getProductsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getProductsData = async () => {
        const result = await getProducts()
        if (result.status) {
            if (result.data?.length !== 0) {
                let product = []
                result.data.forEach(e => {
                    e.type = 'product'
                    product.push(e)
                })
                getServicesData(product)
            }
        }
    }
    
    const getServicesData = async filteredPostList => {
        const result = await getServices()
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'service'
                    if (typeof e.trash === "undefined")
                        serviceList.push(e)
                    else if (!e.trash)
                        serviceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...serviceList]
                getMicroServicesData(filteredPostList)
            }
        }
    }
    
    const getMicroServicesData = async (filteredPostList) => {
        const result = await getMicroServices()
        if (result.status) {
            if (result.data?.length !== 0) {
                let microServiceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'microService'
                    if (typeof e.trash === "undefined")
                        microServiceList.push(e)
                    else if (!e.trash)
                        microServiceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...microServiceList].filter(e => {
                    if (e.status === 'private' || e.status === 'private' || e.status === 'private') {
                        if (!e.company) return true
                        if (e.company) {
                            e.company = JSON.parse(e.company)
                        }
                        if (e.company?.value) {
                            return companyId === e.company.value
                        }
                        return true
                    } else return false
                })
                setrecommendationList(filteredPostList)
                setfilteredPostList(filteredPostList)
            }
        }
    }
    
    const getCarsData = async (uid = false) => {
        let result, title = 'Car List'
        if (uid) {
            result = await getCars({ownerId: uid})
        } else {
            result = await getAllCars()
        }
        let tableData = [], user = []
        if (result.status) {
            result.data.reverse().forEach(e => {
                let found = false
                if (e.carData) {
                    if(isValidJson(e.carData)) {
                        e.carData = JSON.parse(e.carData)
                    }
                    e.modelKey = e.modelKey ?? (e.carData.image_path.substring(0, e.carData.image_path.lastIndexOf('.')) || e.carData.image_path)
                }
                user.forEach(u => {
                    if (u.value === e.ownerId)
                        found = true
                })
                if (!found)
                    user.push({ value: e.ownerId, label: e.ownerName })
                tableData.push(e)
            })
            if (uid) {
                user.forEach(e => {
                    if (e.value === uid)
                        title += " for "+e.label
                })
            }
            settableData(tableData)
            setfilteredList(tableData)
            setuser(user)
            setactiveOwnerId(uid)
            settitle(title)
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashEvent = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getCarsData()
            showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    
    const recommendFor = item => {
        setactiveCar(item)
        setactiveAction('recommend')
        openModal('recommendation-modal')
    }
    
    const addWorkData = item => {
        setactiveCar(item)
        setactiveAction('work')
        openModal('workdata-modal')
    }
    
    const pushRecommendation = async () => {
        let recommendationData = {
            ownerId: activeCar.ownerId,
            uid: generateUID(),
            carDetails: JSON.stringify(activeCar),
            comments,
            carImages: JSON.stringify(carImages),
            recommendData: JSON.stringify(displayedRecommendationsList)
        }
        const result = await saveRecommendation(recommendationData)
        if (result.status) {
            showAlert({type: 'success', msg: 'Recommendation added successfully!' })
            closeModal('recommendation-modal')
            setactiveAction('')
            setactiveCar(null)
            setcomments('')
            setfileArray([])
            setdisplayedRecommendationsList([])
            setsearchKey('')
        }
    }
    
    const pushWorkData = async () => {
        let workData = {
            ownerId: activeCar.ownerId,
            uid: generateUID(),
            carDetails: JSON.stringify(activeCar),
            comments,
            carImages: JSON.stringify(carImages),
            recommendData: JSON.stringify(displayedRecommendationsList)
        }
        const result = await saveBusinessWork(workData)
        if (result.status) {
            showAlert({type: 'success', msg: 'Work data on car added successfully!' })
            closeModal('workdata-modal')
            setactiveAction('')
            setactiveCar(null)
            setcomments('')
            setfileArray([])
            setdisplayedRecommendationsList([])
            setsearchKey('')
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            if (filterItems(item)) {
                let e = {
                    srNo: index + 1,
                    registrationNo: item.registrationNo && item.registrationNo.toUpperCase(),
                    image: item.carData?.image_path?
                        <Img source='carCDN' src={item?.carData?.image_path} style={{maxHeight: 40, maxWidth: 40}} />:
                        <p>No Image</p>,
                    carName: item.carData?.name+' '+item.carData?.Segment,
                    modelKey: item.modelKey,
                    ownerName: !activeOwnerId && item.ownerName,
                    action: item.status === 'active' &&
                        <div className="action-bar">
                            <Actor onClick={() => navigateTo({route: '/admin/car/edit/'+item.uid, newTab: true})} type='edit' />
                            <Actor onClick={() => recommendFor(item)} type='cart' title='Add Recommendations' />
                            <Actor onClick={() => addWorkData(item)} type='setting' title='Add Workdata' />
                        </div>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const uploadFiles = async () => {
        if (Array.isArray(file) && file.length > 0) {
            file.forEach(e => {
                carImages.push(e.fileName)
            })
            for (let i = 0; i < file.length; i++) {
                await uploadCall(file[i])
                setTimeout(() => {
                    if (i === file.length-1) {
                        setfile([])
                        setfileArray([])
                        setcarImages(carImages)
                        uploadFiles()
                    }
                }, 1000)
            }
        } else {
            if (activeAction === 'recommend') {
                pushRecommendation()
            } else {
                pushWorkData()
            }
        }
    }

    const selectRecommendation = item => {
        item.uid = generateUID()
        if (item.sku?.includes("fuel-refill")) {
            item.fuelQuantity = fuelQuantity
            item.fuelType = fuelType
            closeModal('fuel-modal')
        }
        displayedRecommendationsList.push(item)
        setdisplayedRecommendationsList(displayedRecommendationsList)
        setfilteredWorkPostList([])
        setfilteredPostList([])
        setsearchPostKey('')
    }
    const uploadCall = async file => {
        let error = false, errorMsg = ''
        if (!error) {
            file.uid = generateUID()
            const result = await save(file)
            if (result.status) {
                uploadCount++
                setuploadCount(uploadCount)
                if (uploadCount === fileArray.length) {
                    setfileArray([])
                    setfile([])
                    setuploadCount(0)
                    if (activeAction === 'recommend') {
                        pushRecommendation()
                    } else {
                        pushWorkData()
                    }
                }
            } else showAlert({ type: 'error', msg: 'Unable to add media!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const selectFiles = async (e) => {
        let fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        setfileObj(fileObj)
        for (let i = 0; i < fileObj[0].length; i++) {
            const result = await getBase64(fileObj[0][i])
            let fileData = {
                url: URL.createObjectURL(fileObj[0][i]),
                data: result,
                type: fileObj[0][i].type,
                fileName: fileObj[0][i].name,
                size: fileObj[0][i].size
            }
            if (fileData.size <= 4000000)
                fileArray.push(fileData)
            else showAlert({ type: 'error', msg: 'File is too large.' })
            setfile(fileArray)
            setfileArray(fileArray)
        }
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const closeWorkDataModal = () => {
        setactiveCar(null)
        setcomments('')
        setfileArray([])
        setdisplayedRecommendationsList([])
        setsearchKey('')
        closeModal('workdata-modal')
    }

    const closeRecommendationModal = () => {
        setactiveCar(null)
        setcomments('')
        setfileArray([])
        setdisplayedRecommendationsList([])
        setsearchKey('')
        closeModal('recommendation-modal')
    }

    const searchPost = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchPostKey(e.target.value)
            let tempTitle = '', filteredPostList = []
            if (activeAction === 'work') {
                recommendationList = workPostList
            }
            recommendationList.forEach(e => {
                tempTitle = e.title ? e.title.toLowerCase() : e.value.toLowerCase()
                if (tempTitle.search(searchPostKey.toLowerCase()) !== -1) {
                    filteredPostList.push(e)
                }
            })
            setfilteredPostList(filteredPostList)
            setfilteredWorkPostList(filteredPostList)
        }
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.registrationNo.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }
    
    const filterItems = e => {
        return owner === null || (e.ownerId === owner.value)
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Sr. No.',
                    key: 'srNo'
                },
                {
                    label: 'Registration No.',
                    key: 'registrationNo'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Car Name',
                    key: 'carName'
                },
                {
                    label: 'Model Key',
                    key: 'modelKey'
                },
                {
                    label: !activeOwnerId && 'Owner Name',
                    key: 'ownerName'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(filteredList)
        })
        let exportedData = []
        filteredList.forEach(e => {
            let ex = {
                ownerId: e.ownerId,
                uid: e.uid,
                registrationNo: e.registrationNo,
                registrationDate: e.registrationDate ?? '',
                chassisNo: e.chassisNo ?? '',
                engineNo: e.engineNo ?? '',
                ownerName: e.ownerName ?? '',
                vehicleClass: e.vehicleClass ?? '',
                maker: e.maker ?? '',
                fitness: e.fitness ?? '',
                mvTax: e.mvTax ?? '',
                insurance: e.insurance ?? '',
                pucc: e.pucc ?? '',
                fuelType: e.fuelType?.label ?? fuelOption[0].label,
                emission: e.emission ?? '',
                rcStatus: e.rcStatus ?? '',
                financed: e.financed ?? false,
                rcImage: e.rcImage ?? '',
                status: e.status ?? 'active',
                shoot360: e.shoot360 ?? false,
                shoot360Url: e.shoot360Url ?? '',
                modelKey: e.modelKey
            }
            exportedData.push(ex)
        })
        setexportedData(exportedData)
    }, [filteredList])
    return {
        setdisplayedRecommendationsList,
        setactiveItem,
        selectRecommendation,
        trackScrolling,
        title,
        filteredList,
        exportedData,
        activeOwnerId,
        owner,
        setowner,
        user,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
        closeRecommendationModal,
        filteredPostList,
        uploadFiles,
        closeWorkDataModal,
        searchPost,
        searchPostKey,
        workPostList,
        displayedRecommendationsList,
        activeCar,
        selectFiles,
        fileArray,
        setfileArray,
        comments,
        setcomments,
        fuelQuantity,
        setfuelQuantity,
        fuelType,
        setfuelType,
        activeItem,
    }
}