import { useContext, useEffect, useState } from "react"
import { config, fuelOption, packagesOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useEditUsersCarList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {commonPageSize} = config
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        showAlert,
    } = useService('Misc')
    const {isValidVehicleRegistrationNumber} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getCar,
        updateCar,
    } = useService('Car')
    const user = getLoggedInUserData()
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [shoot360Url, setshoot360Url] = useState('')
    const [shoot360, setshoot360] = useState(false)
    const [registrationNo, setregistrationNo] = useState('')
    const [registrationDate, setregistrationDate] = useState('')
    const [chassisNo, setchassisNo] = useState('')
    const [engineNo, setengineNo] = useState('')
    const [mode, setmode] = useState('Add')
    const [ownerName, setownerName] = useState(user.name)
    const [vehicleClass, setvehicleClass] = useState('')
    const [activeFuelType, setactiveFuelType] = useState(0)
    const [fuelType, setfuelType] = useState(fuelOption[0])
    const [packageType, setpackageType] = useState(packagesOption[0])
    const [activePackageType, setactivePackageType] = useState(packagesOption[0])
    const [maker, setmaker] = useState('')
    const [fitness, setfitness] = useState('')
    const [mvTax, setmvTax] = useState('')
    const [insurance, setinsurance] = useState('')
    const [pucc, setpucc] = useState('')
    const [emission, setemission] = useState('')
    const [rcStatus, setrcStatus] = useState('')
    const [financed, setfinanced] = useState('')
    const [showForm, setshowForm] = useState(false)
    const [additionalCarInfo, setadditionalCarInfo] = useState(false)
    const [carData, setcarData] = useState(null)
    const [uid, setuid] = useState('')
    const [showInsurance, setshowInsurance] = useState(true)
    const [showRc, setshowRc] = useState(true)
    const [rcImage, setrcImage] = useState('')
    const [insuranceImage, setinsuranceImage] = useState('')
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Edit User Cars List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        getCarData({uid: urlData[4]})
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getCarData = async (query) => {
        const result = await getCar(query)
        if (result.status) {
            let carData = result.data[0]
            if (typeof carData.rcImage === 'undefined' || carData.rcImage === '' ) {
                showRc = false
                carData.rcImage = '[]'
            }
            if (typeof carData.insuranceImage === 'undefined' || carData.insuranceImage === '') {
                showInsurance = false
                carData.insuranceImage = '[]'
            }
            setmode('Edit')
            setshoot360Url(carData.shoot360Url)
            setshoot360(carData.shoot360)
            setregistrationNo(carData.registrationNo)
            setregistrationDate(carData.registrationDate)
            setchassisNo(carData.chassisNo)
            setengineNo(carData.engineNo)
            setownerName(carData.ownerName)
            setvehicleClass(carData.vehicleClass)
            setmaker(carData.maker)
            setfitness(carData.fitness)
            setmvTax(carData.mvTax)
            setinsurance(carData.insurance)
            setpucc(carData.pucc)
            setfuelType(typeof carData.fuelType === "object"? JSON.parse(carData.fuelType) : fuelOption[activeFuelType])
            setemission(carData.emission)
            setrcStatus(carData.rcStatus)
            setfinanced(carData.financed)
            setcarData(JSON.parse(carData.carData))
            setuid(carData.uid)
            setshowForm(true)
            setrcImage(carData.rcImage)
            setinsuranceImage(carData.insuranceImage)
            setshowRc(showRc)
            setshowInsurance(showInsurance)
        }
    }
    
    const handleChange = fuelType => {
        setfuelType(fuelType)
    }
    
    const handleChangePackage = packageType => {
        setpackageType(packageType)
        setactivePackageType(packageType)
    }
    
    const addCar = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        carObject = {
            shoot360Url: shoot360Url,
            shoot360: shoot360,
            registrationNo: registrationNo,
            registrationDate: registrationDate,
            chassisNo: chassisNo,
            engineNo: engineNo,
            ownerName: ownerName,
            vehicleClass: vehicleClass,
            maker: maker,
            fitness: fitness,
            mvTax: mvTax,
            insurance: insurance,
            pucc: pucc,
            fuelType: JSON.stringify(fuelType),
            emission: emission,
            rcImage: rcImage,
            insuranceImage: insuranceImage,
            rcStatus: rcStatus,
            financed: financed,
            carData: JSON.stringify(carData)
        }
        if (registrationNo === '') {
            errorMsg = 'Please enter car Registration Number!'
            error = true
        } else if (fuelType === '') {
            errorMsg = 'Please select car fuel'
            error = true
        } else if (!isValidVehicleRegistrationNumber(registrationNo)) {
            errorMsg = 'Please enter a valid car Registration Number!'
            error = true
        }
        if (!error) {
            let data = {
                query: JSON.stringify({uid}),
                carData: JSON.stringify(carObject)
            }
            const result = await updateCar(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Car updated successfully!' })
                navigateTo({route: '/admin/car/list'})
            } else showAlert({ type: 'error', msg: 'Unable to update car!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        trackScrolling,
        addCar,
        registrationNo,
        ownerName,
        vehicleClass,
        fuelType,
        maker,
        setshoot360,
        shoot360,
        shoot360Url,
        setshoot360Url,
        registrationDate,
        setregistrationDate,
        chassisNo,
        setchassisNo,
        engineNo,
        setengineNo,
        fitness,
        setfitness,
        mvTax,
        setmvTax,
        insurance,
        setinsurance,
        pucc,
        setpucc,
        emission,
        setemission,
        rcStatus,
        setrcStatus,
        financed,
        setfinanced,
    }
}