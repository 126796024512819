import { useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useFeedbackOrders = () => {
    const Link = useElement('Link')
    const {
        fetchByAttr,
        fetchAll,
    } = useService('Data', 'feedback')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        getFranchiseData,
        showAlert,
        getFormattedDate,
        getFormattedTime,
    } = useService('Misc')
    const {getLoggedInUserData} = useService('User')
    const Rating = useElement('Rating')
    const user = getLoggedInUserData() 
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [feedbackList, setfeedbackList] = useState([])
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Order Feedbacks', 'admin')
        getFeedbacksData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getFeedbacksData = async () => {
        let result = ''
        if(['franchiseOwner', 'franchiseManager'].includes(user?.role)) {
            result = await fetchByAttr({
                source: 'franchise',
                franchiseId: getFranchiseData().value
            })
        } else {
            result = await fetchAll()
        }
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No feedback given yet!' })
            } else setfeedbackList(result.data.reverse())
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                data: <div className='mb40'>
                    <p><b><Link className="dark" href={"/admin/order/view/"+item.orderId}>#{item.orderId}</Link></b></p>
                    <p><b>Date:</b> {getFormattedDate(item.uid)+' '+getFormattedTime(item.uid)}</p>
                    {item.rating && <div className="ans">
                        <div className="common-rating-style">
                            <Rating rating={item.rating} />
                        </div>
                    </div>}
                    <p><b>Comments:</b> {item.comments}</p>
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            content: getTableData(feedbackList)
        })
    }, [feedbackList])
    return {
        trackScrolling,
        tableContent,
        activeShownTotal,
    }
}