import { useComponent, useElement, useService } from '../../../hooks'
import { useSideCart } from './hooks'

export const SideCart = ({toggleCart}) => {
    const CartSingleItem = useComponent('CartSingleItem')
    const {
        toIndianCurrency,
        proceedToCheckOut,
    } = useService('Misc')
    const Img = useElement('Img')
    const Link = useElement('Link')
    const {
        removeProduct,
        cartData,
        cartTotal,
    } = useSideCart()
    const renderCartData = cartData => {
        return cartData.map(( item, index ) => {
            return <CartSingleItem
                key={index}
                item={item}
                removeProduct={removeProduct}
            />
        })
    }
    
    return <>
        <div className="cart-title-wrap">
            <div className="title">
                <h3 className="heading-03">Cart</h3>
            </div>
            <div className="show-cart">
                <span onClick={toggleCart} className="closebtn" title="Close Overlay"><i className="hi-close"></i></span>
            </div>
        </div>
        <div className="cart-content">
            <div className="cart-item-wrap">
                {
                    cartData.length === 0?
                    <div className="empty-cart">
                        <Img source='internal' src={require('../../../../assets/images/emptycart.png')} alt="empty-cart" />
                        <p className="tac">No items in the cart yet!</p>
                    </div>:
                    renderCartData(cartData || [])
                }
            </div>
        </div>
        {
            cartTotal !== 0 &&
            <div className="calculate-checkout">
                <div className="cart-single-item subtotal">
                    <div className="item-left">
                        <h5 className="heading-05">Total:</h5>
                    </div>
                    <div className="item-right amount">{toIndianCurrency(cartTotal)}</div>
                </div>
                <div className="main-btn-wrap  p20">
                    <Link href="/cart" className="btn black btn-block btn-primary">VIEW CART</Link>
                </div>
                <div className="main-btn-wrap pt0 p20">
                    <p onClick={proceedToCheckOut} className="btn black btn-block btn-outline">CHECKOUT</p>
                </div>
            </div>
        }
    </>
}
