import { useEffect, useState } from "react"
import { useService } from "../../../hooks"
import { searchRegex } from "../../../constant"

export const useBlogSideBar = () => {
    const {fetchByAttr} = useService('Data', 'post')
    const {getProduct} = useService('Product')
    const [recentPost, setrecentPost] = useState([])
    const [productData, setproductData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [filteredPostList, setfilteredPostList] = useState([])
    const [searchPostKey, setsearchPostKey] = useState('')
    const [searchKey, setsearchKey] = useState('')
    const searchProduct = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = [], tempSku = ''
            console.log('productData')
            productData.forEach(e => {
                tempTitle = e.title?.toLowerCase()
                tempSku = e.sku?.toLowerCase()
                if (tempSku.search(searchKey.toLowerCase()) !== -1 || tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    const searchPost = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchPostKey(e.target.value)
            let tempTitle = '', filteredPostList = []
            recentPost.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchPostKey.toLowerCase()) !== -1) {
                    filteredPostList.push(e)
                }
            })
            setfilteredPostList(filteredPostList)
        }
    }
    const getPostsData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            setrecentPost(result.data)
            setfilteredPostList(result.data)
        }
    }
    
    const getProductsData = async () => {
        const result = await getProduct({status: 'publish'})
        if (result.status) {
            if (result.data?.length !== 0) {
                setfilteredList(result.data)
                setproductData(result.data)
            }
        }
    }

    useEffect(() => {
        getPostsData()
        getProductsData()
    }, [])

    return {
        searchProduct,
        searchPost,
        getPostsData,
        recentPost,
        filteredList,
        filteredPostList,
        searchPostKey,
        searchKey,
    }
}