import { useEffect, useState } from 'react'
import { config, activeDateFilter as ADF, searchRegex} from '../../../../constant'
import { useService } from '../../../../hooks'

export const useCompanyStockDetailDashboard = () => {
    const {commonPageSize} = config
    const {fetchByAttr} = useService('Data', 'b2bDailyStockData')
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getPreviousDate,
        showPreloader,
        hidePreloader,
    } = useService('Misc')
    const user = getLoggedInUserData()
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState([])
    const [searchPostKey, setsearchPostKey] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [b2bDailyStockData, setb2bDailyStockData] = useState([])
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[4])
    const [branches, setbranches] = useState([])
    const [fromFilterDate, setfromFilterDate] = useState(getPreviousDate(2))
    const [toFilterDate, settoFilterDate] = useState(getPreviousDate(1))
    const [filteredList, setfilteredList] = useState([])
    const [activeBranches, setactiveBranches] = useState([])
    
    useEffect(() => {
        setTitle('Daily Stock Detail Report')
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getBranches(orgId)
        } else {
            getB2bDailyStockData()
        }
        document.getElementsByClassName('table-wrapper')[0].addEventListener('scroll', trackScrolling)
        return () => {
            document.getElementsByClassName('table-wrapper')[0].removeEventListener('scroll', trackScrolling)
        }
    })
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementsByClassName('table-wrapper')[0]
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const searchPost = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchPostKey(e.target.value)
            let tempTitle = '', filteredList = []
            b2bDailyStockData.forEach(e => {
                tempTitle = e.registrationNo.toLowerCase()
                if (tempTitle.search(searchPostKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }

    const getBranches = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getB2bDailyStockData(activeBranchIds)
    }
    
    const getB2bDailyStockData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setb2bDailyStockData([])
            } else {
                let b2bDailyStockData = result.data.reverse()
                setb2bDailyStockData(b2bDailyStockData)
                setfilteredList(b2bDailyStockData)
            }
        }
    }
    
    const getTableData = filterData => {
        let data = [], i = 0
        filterData.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                i++;
                let yardName = activeBranches.length === 0 ? user.name : ''
                activeBranches.forEach(a => {
                    if (a.value === e.ownerId) {
                        yardName = a.label
                    }
                })
                let el = {
                    'Sr. No.' : i,
                    'Date': e['Date'],
                    'Yard name': yardName,
                    'Vehicle no': e['Vehicle no'],
                    'Vehicle model': e['Vehicle model'],
                    'Inventory Status': e['Inventory Status']
                }
                data.push(el)
            }
        })
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Sr. No.',
                    key: 'Sr. No.'
                },
                {
                    label: 'Date',
                    key: 'Date'
                },
                {
                    label: 'Yard name',
                    key: 'Yard name'
                },
                {
                    label: 'Vehicle no',
                    key: 'Vehicle no'
                },
                {
                    label: 'Vehicle model',
                    key: 'Vehicle model'
                },
                {
                    label: 'Inventory Status',
                    key: 'Inventory Status'
                }
            ],
            content: getTableData(filteredList)
        })
        let exportedData = []
        filteredList.forEach(e => {
            exportedData.push(e)
        })
        setexportedData(exportedData)
    }, [filteredList])

    const selectCompany = e => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }
    return {
        user,
        exportedData,
        branches,
        activeBranches,
        selectCompany,
        setfromFilterDate,
        fromFilterDate,
        settoFilterDate,
        toFilterDate,
        searchPostKey,
        searchPost,
        tableContent,
        activeShownTotal,
    }
}