import { useEffect, useState } from "react"
import { faqCategory, config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useFaq = () => {
    const Actor = useElement('Actor')
    const {
        fetchAll,
        remove,
        save,
        update,
    } = useService('Data', 'faq')
    const faqCats = faqCategory
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        showAlert,
        generateUID,
        closeModal,
        openModal,
    } = useService('Misc')
    const [tableContent, settableContent] = useState({})
    const {commonPageSize} = config
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [faqList, setfaqList] = useState([])
    const [query, setquery] = useState('')
    const [activeItem, setactiveItem] = useState(null)
    const [activeCategoryIndex, setactiveCategoryIndex] = useState(0)
    const [ans, setans] = useState('')
    const [uid, setuid] = useState('')
    const [mode, setmode] = useState('Add')
    const [category, setcategory] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('FAQs', 'admin')
        getFaqsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getFaqsData = async () => {
        let faqList = []
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No faq added yet!' })
            } else {
                result.data.forEach(e => {
                    faqList.push(e)
                })
            }
            setfaqList(faqList)
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashFaq = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getFaqsData()
            showAlert({ type: 'success', msg: 'Faq deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete faq!' })
    }
    
    const addFaq = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        faqObject = {
            query,
            ans,
            category: JSON.stringify(category)
        }
        if (query === '' ) {
            errorMsg = 'Please enter Query!'
            error = true
        } else if (ans === '') {
            errorMsg = 'Please enter Answer'
            error = true
        } else if (category === '') {
            errorMsg = 'Please select faq category'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                faqObject.uid = generateUID()
                const result = await save(faqObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Faq added successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add faq!' })
                }
                setquery('')
                setans('')
                setcategory('')
                setuid('')
                closeModal("faq-modal")
                getFaqsData()
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    faqData: JSON.stringify(faqObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Faq updated successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update faq!' })
                }
                setquery('')
                setans('')
                setcategory('')
                setuid('')
                closeModal("faq-modal")
                getFaqsData()
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let activeCategoryIndex = 0
            let e = {
                data: <div className='mb40'>
                    <p className="query"><b>Query: </b>{item.query}</p>
                    <p className="ans"><b>Category: </b>{JSON.parse(item.category).label}</p>
                    <p className="ans"><b>Answer: </b>{item.ans}</p>
                </div>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        setactiveCategoryIndex(faqCats.findIndex(e => e.value === JSON.parse(item.category).value))
                        setcategory(faqCats.find(e => e.value === JSON.parse(item.category).value))
                        setmode('Edit')
                        setquery(item.query)
                        setans(item.ans)
                        setuid(item.uid)
                        setactiveCategoryIndex(activeCategoryIndex)
                        openModal("faq-modal")
                    }} />
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Queries',
                    key: 'queries'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(faqList)
        })
    }, [faqList])
    return {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashFaq,
        trashCancel,
        showTrashConfirm,
        setuid,
        mode,
        addFaq,
        query,
        setquery,
        category,
        setcategory,
        faqCats,
        ans,
        setans,
        closeModal,
        openModal,
    }
}