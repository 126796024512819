import { useContext, useEffect, useState } from "react"
import { base, config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useCompanyWorkList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const Img = useElement('Img')
    const {
        remove,
        save,
    } = useService('Data', 'media')
    const {saveRecommendation} = useService('Data', 'recommendation')
    const {
        fetchByAttr,
        fetchAll,
        update,
    } = useService('Data', 'companyOrder')
    const {
        getLoggedInUserData,
        isAllowedAdmin,
        getUsers,
    } = useService('User')
    const {getServices} = useService('Service')
    const {setTitle} = useService('Meta')
    const {
        isEmpty,
        showPreloader,
        hidePreloader,
        showAlert,
        openModal,
        generateUID,
        closeModal,
        getFormattedDate,
        getBase64,
    } = useService('Misc')
    const {getMicroServices} = useService('MicroService')
    const {isValidJson} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {getProducts} = useService('Product')
    const {commonPageSize} = config
    const user = getLoggedInUserData()
    const [tableContent, settableContent] = useState({})
    const [assignWorkers, setassignWorkers] = useState([])
    const [assignWorker, setassignWorker] = useState('')
    const [activeItem, setactiveItem] = useState(null)
    const [searchKey, setsearchKey] = useState('')
    const [activeRecommendation, setactiveRecommendation] = useState(null)
    const [activeAction, setactiveAction] = useState('')
    const [uploadCount, setuploadCount] = useState(0)
    const [carImages, setcarImages] = useState([])
    const [title, settitle] = useState('Business Work List')
    const [displayedRecommendationsList, setdisplayedRecommendationsList] = useState([])
    const [filteredPostList, setfilteredPostList] = useState([])
    const [fileObj, setfileObj] = useState([])
    const [file, setfile] = useState([])
    const [fileArray, setfileArray] = useState([])
    const [comments, setcomments] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeCar, setactiveCar] = useState(null)
    const [orderData, setorderData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [recommendationList, setrecommendationList] = useState([])

    useEffect(() => {
        setTitle('Business Work List', 'admin')
        getWorkersData()
        const urlData = urlToSplitData(getLocationData().pathname)
        if (!isEmpty(urlData[4]) && urlData[4]!== 'list')
            getWorkData(urlData[4])
        else
            getWorkData()
        getProductsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getProductsData = async () => {
        const result = await getProducts()
        if (result.status) {
            if (result.data?.length !== 0) {
                let product = []
                result.data.forEach(e => {
                    e.type = 'product'
                    product.push(e)
                })
                getServicesData(product)
            }
        }
    }
    
    const getServicesData = async filteredPostList => {
        const result = await getServices()
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'service'
                    if (typeof e.trash === "undefined")
                        serviceList.push(e)
                    else if (!e.trash)
                        serviceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...serviceList]
                getMicroServicesData(filteredPostList)
            }
        }
    }
    
    const getMicroServicesData = async (filteredPostList) => {
        const result = await getMicroServices()
        if (result.status) {
            if (result.data?.length !== 0) {
                let microServiceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'microService'
                    if (typeof e.trash === "undefined")
                        microServiceList.push(e)
                    else if (!e.trash)
                        microServiceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...microServiceList]
                setrecommendationList(filteredPostList)
                setfilteredPostList(filteredPostList)
            }
        }
    }
    
    const getWorkData = async (uid = false) => {
        let result
        if (uid)
            result = await fetchByAttr({ownerId: uid})
        else
            result = await fetchAll()

        if (result.status) {
            if (result.data?.length === 0)
                setorderData(null)
            else {
                let orderData = []
                result.data.reverse().forEach(e => {
                    if (
                        isAllowedAdmin() ||
                        (user.role === 'worker' && e.assignWorker && e.assignWorker.value === user.uid)
                    ) {
                        if (e.carDetails && e.carDetails !== "undefined" && isValidJson(e.carDetails) && e.orderData && isValidJson(e.orderData)) {
                            e.carDetails = isValidJson(e.carDetails) ? JSON.parse(e.carDetails) : false
                            e.carImages = isValidJson(e.carImages) ? JSON.parse(e.carImages) : false
                            e.orderData = isValidJson(e.orderData) ? JSON.parse(e.orderData) : false
                            orderData.push(e)
                        }
                    }
                })
                setorderData(orderData)
                setfilteredList(orderData)
            }
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashEvent = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getWorkData()
            showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    
    const addWorkData = item => {
        setactiveCar(item)
        setactiveAction('work')
        openModal('work-assign-modal')
    }
    
    const pushRecommendation = async () => {
        let recommendationData = {
            ownerId: activeCar.ownerId,
            uid: generateUID(),
            carDetails: JSON.stringify(activeCar),
            comments,
            carImages: JSON.stringify(carImages),
            recommendData: JSON.stringify(displayedRecommendationsList)
        }
        const result = await saveRecommendation(recommendationData)
        if (result.status) {
            showAlert({type: 'success', msg: 'Recommendation added successfully!' })
            closeModal('work-assign-modal')
            setactiveAction('')
            setactiveCar(null)
            setcomments('')
            setfileArray([])
            setdisplayedRecommendationsList([])
            setsearchKey('')
        }
    }
    
    const pushWorkData = async () => {
        let workData = {
            status: 'completed',
            comments: activeRecommendation.comments,
            carImages: JSON.stringify(carImages),
            uid: activeRecommendation.uid
        }
        let data = {
            query: JSON.stringify({uid: workData.uid}),
            companyOrderData: JSON.stringify(workData)
        }
        const result = await update(data)
        if (result.status) {
            showAlert({type: 'success', msg: 'Work data on car added successfully!' })
            closeModal('work-done-modal')
            setactiveCar(null)
            setcomments('')
            setfileArray([])
            setdisplayedRecommendationsList([])
            setsearchKey('')
        }
    }
    
    const chooseWorker = item => {
        setactiveRecommendation(item)
        openModal('work-assign-modal')
    }
    
    const markComplete = item => {
        setactiveRecommendation(item)
        setactiveCar(item.carDetails)
        openModal('work-done-modal')
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.map(( e, index ) => {
            let car = e.carDetails
            let carData = typeof car.carData === 'string' ? JSON.parse(car.carData) : car.carData
            let product = e.orderData[0]
            let item = {
                orderId: '#'+e.uid,
                actionDate: getFormattedDate(e.actionDate),
                carData: <div className='flex'>
                    {
                        carData?.image_path && <Img source='carCDN' src={carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />
                    }
                    <div className='fs12'>
                        {(car.registrationNo).toUpperCase()}<br />
                        {carData.name+' '+carData.Segment}
                    </div>
                </div>,
                image: <div className='flex'>
                    {product.image && <Img src={JSON.parse(product.image)[0]} alt={product.title} style={{ width: 50, borderRadius: 5, marginRight: 5, marginBottom: 'auto' }} />}
                    { product.title ? product.title : product.value }
                </div>,
                status: <Badge item={e.status} />,
                assignedWorker: e.status === 'assigned' && <p>{e.assignWorker.label}</p>,
                action: <div className="action-bar">
                    {
                        user.role !== 'worker' ? <>{
                            <>
                                <Actor type='person' onClick={() => chooseWorker(e)} />
                                <>
                                    {
                                        e.invoiceNo ?
                                        <Actor type='download' onClick={() => navigateTo({route: base.invoiceBaseUrl+"/invoice_"+e.invoiceNo+".pdf", newTab: true})} /> : '' }
                                </>
                                <Actor type='person' onClick={() => chooseWorker(e)} />
                            </>
                        }</>:<Actor type='check' onClick={() => markComplete(e)} />
                    }
                </div>
            }
            data.push(item)
        })
        return data
    }
    
    const uploadFiles = async () => {
        file.forEach(e => {
            carImages.push(e.fileName)
        })
        for (let i = 0; i < file.length; i++) {
            await uploadCall(file[i])
            setTimeout(() => {
                if (i === file.length-1) {
                    setfile([])
                    setfileArray([])
                    setcarImages(carImages)
                }
            }, 1000)
        }
    }
    
    const uploadCall = async file => {
        let errorMsg = '', error = false
        if (!error) {
            file.uid = generateUID()
            const result = await save(file)
            if (result.status) {
                uploadCount++
                setuploadCount(uploadCount)
                if (uploadCount === fileArray.length) {
                    setfileArray([])
                    setfile([])
                    setuploadCount(0)
                    pushWorkData()
                }
            } else showAlert({ type: 'error', msg: 'Unable to add media!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const selectFiles = async (e) => {
        let fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        setfileObj(fileObj)
        for (let i = 0; i < fileObj[0].length; i++) {
            const result = await getBase64(fileObj[0][i])
            let fileData = {
                url: URL.createObjectURL(fileObj[0][i]),
                data: result,
                type: fileObj[0][i].type,
                fileName: fileObj[0][i].name,
                size: fileObj[0][i].size
            }
            if (fileData.size <= 4000000)
                fileArray.push(fileData)
            else showAlert({ type: 'error', msg: 'File is too large.' })
            setfile(fileArray)
            setfileArray(fileArray)
        }
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            orderData.forEach(e => {
                tempTitle = e.registrationNo.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }
    
    const resetSearch = () => {
        setfilteredList(orderData)
        setsearchKey('')
    }

    const getWorkersData = async () => {
        let tableDataWorkers = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No user added yet!' })
            else {
                result.data.forEach(result => {
                    if (result.role === 'worker') {
                        tableDataWorkers.push({label: result.name, value: result.uid})
                        setassignWorkers(tableDataWorkers)
                    }
                })
            }
        }
    }
    
    const closeWorkDoneModal = () => {
        setactiveCar(null)
        setcomments('')
        setfileArray([])
        setdisplayedRecommendationsList([])
        setsearchKey('')
        closeModal('work-done-modal')
    }

    const closeWorkAssignModal = () => {
        setactiveCar(null)
        setcomments('')
        setfileArray([])
        setdisplayedRecommendationsList([])
        setsearchKey('')
        closeModal('work-assign-modal')
    }
    
    const assignAWorker = async () => {
        let taskData = {
            uid: activeRecommendation.uid,
            assignWorker,
            status: 'assigned'
        }
        let data = {
            query: JSON.stringify({uid: taskData.uid}),
            companyOrderData: JSON.stringify(taskData)
        }
        const result = await update(data)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Worker assigned to order updated successfully!' })
            setactiveRecommendation(null)
            setassignWorker('')
            getWorkersData()
            closeModal("work-assign-modal")
        } else showAlert({ type: 'error', msg: 'Unable to assigne Worker!' })
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Car',
                    key: 'car'
                },
                {
                    label: 'Product / Service',
                    key: 'productOrService'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Assigned Worker',
                    key: 'assignedWorker'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(filteredList)
        })
        content: getTableData(filteredList)
    }, [])
    return {
        trackScrolling,
        title,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
        closeWorkAssignModal,
        assignWorker,
        setassignWorker,
        assignWorkers,
        assignAWorker,
        closeWorkDoneModal,
        activeCar,
        selectFiles,
        fileArray,
        setfileArray,
        comments,
        setcomments,
        uploadFiles,
    }
}