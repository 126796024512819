import { useService } from "../../hooks"
export const Table = ({data, pageSize, tableClass = '', total, showHeader = false, showTotal = true}) => {
    const {checkIfEmpty} = useService('Misc')

    const renderHeader = () => {
        return <tr>{
            data?.headers?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>
    }
    
    const renderContent = () => {
        return data?.content?.map((e, i) => {
            if ((pageSize && i < pageSize) || !pageSize)
            return <tr key={i}>
                {
                    Object.entries(e).map(([key, value]) => {
                        return <td key={key+'_'+i}>
                            {checkIfEmpty(value)}
                        </td>
                    })
                }
            </tr>
        })
    }
    
    const renderFooter = () => {
        return <tr> {
            data?.footers?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>
    }

    return <div className="table-wrapper overflow-auto">
        {showHeader && <div className="table-metadata">
            <span className="widget">Total: <b>{total}</b></span>
        </div>}
        {showTotal && <p>Total: <b>{data?.content?.length ?? 0}</b></p>}
        <table id="list-wrapper" className={tableClass}>
            {data?.headers && <thead>
                {renderHeader()}
            </thead>}
            {(data?.content || data?.addonBodyContent) && <tbody>
                {renderContent()}
                {data?.addonBodyContent}
            </tbody>}
            {data?.footers && <tfoot>
                {renderFooter()}
            </tfoot>}
        </table>
    </div>
}