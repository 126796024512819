import { useContext, useEffect, useState } from "react"
import { config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const usePageList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const {
        save,
        fetchAll,
        remove,
        update,
    } = useService('Data', 'page')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        generateUID,
        showAlert,
        getFormattedDate,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [searchKey, setsearchKey] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [checkedAll, setcheckedAll] = useState(false)
    const [checkedList, setcheckedList] = useState([])
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [trashAction, settrashAction] = useState(false)

    useEffect(() => {
        setTitle('Pages List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getPageListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const clonePage = async item => {
        let pageObject = { ...item, 
            slug: item.slug+'-copy',
            uid: generateUID()
        }
        const result = await save(pageObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Page clone successfully!' })
            getPageListData()
        } else showAlert({ type: 'error', msg: 'Unable to clone page!' })
    }
    
    const getPageListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            setfilteredList(tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No page added yet!' })
        } else showAlert({ type: 'error', msg: 'No page added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectPage = () => {
        deletePage(activeItem.uid)
    }
    
    const deletePage = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'Page deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getPageListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete page!' })
        } else {
            let pageObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    pageObject = e
            })
            pageObject.trash = task === 'trash'
            delete pageObject._id
            let data = {
                query: JSON.stringify({uid}),
                pageData: JSON.stringify(pageObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Page '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getPageListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' page!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        if (checkedAll) {
            checkedList = filteredList
        }
        checkedList.forEach(e => {
            deletePage(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    <CheckBox isChecked={checkedAll || checkedList.includes(item.uid)} />
                </span>,
                title: item.title,
                publishDate: getFormattedDate(new Date(item.publishDate)),
                status: item.status,
                action: trashAction ?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='undo' onClick={() => deletePage(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='view' onClick={() => navigateTo({route: '/page/'+item.slug, newTab: true})} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/page/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => clonePage(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'PublishDate',
                    key: 'publishDate'
                },
                {
                    label: 'Page Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectPage,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}