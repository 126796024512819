import {cloneElement, useContext, useEffect} from 'react'

import {routes} from '../../../router'
import { useService, useTemplate, useRender, usePage, useElement } from '../../../hooks'
import { Navigator, NavigatorContext } from '../../../context'

export const Content = ({
    subRoutes = []
}) => {
    const PreLoader = useElement('PreLoader')
    const UnderDevelopment = usePage('UnderDevelopment')
    let isMaintenanceModeOn = false
    return <Navigator>
        <PreLoader />
        {(localStorage.getItem('authUser') !=='yes' && isMaintenanceModeOn) ? <UnderDevelopment /> : <RouteParser subRoutes={subRoutes} />}
    </Navigator>
}
const RouteParser = ({
    subRoutes = []
}) => {
    const {navigateTo, activeRoute} = useContext(NavigatorContext)
    const About = usePage('About')
    const AddAddress = usePage('AddAddress')
    const AddCar = usePage('AddCar')
    const AddManager = usePage('AddManager')
    const Blog = usePage('Blog')
    const CarList = usePage('CarList')
    const Cart = usePage('Cart')
    const Chat = usePage('Chat')
    const Checkout = usePage('Checkout')
    const CompanyDashboard = usePage('CompanyDashboard')
    const CompanyManagers = usePage('CompanyManagers')
    const CompanyProfile = usePage('CompanyProfile')
    const CompanyStockDetailDashboard = usePage('CompanyStockDetailDashboard')
    const CompanyWorkDashboard = usePage('CompanyWorkDashboard')
    const CompanyWorkDetailDashboard = usePage('CompanyWorkDetailDashboard')
    const Complaint = usePage('Complaint')
    const Contact = usePage('Contact')
    const DashBoard = usePage('DashBoard')
    const ErrorPage = usePage('ErrorPage')
    const OurEvents = usePage('OurEvents')
    const Faq = usePage('Faq')
    const Feedback = usePage('Feedback')
    const FranchiseWithUs = usePage('FranchiseWithUs')
    const Home = usePage('Home')
    const Login = usePage('Login')
    const Ludo = usePage('Ludo')
    const ManageAddress = usePage('ManageAddress')
    const MicroServices = usePage('MicroServices')
    const MyOrderDetail = usePage('MyOrderDetail')
    const MyOrders = usePage('MyOrders')
    const MyPackageDetail = usePage('MyPackageDetail')
    const MyPackages = usePage('MyPackages')
    const MySubscription = usePage('MySubscription')
    const Notifications = usePage('Notifications')
    const Offers = usePage('Offers')
    const OrderTracking = usePage('OrderTracking')
    const Otp = usePage('Otp')
    const Packages = usePage('Packages')
    const Payment = usePage('Payment')
    const Profile = usePage('Profile')
    const ProjectList = usePage('ProjectList')
    const Register = usePage('Register')
    const Run = usePage('Run')
    const Services = usePage('Services')
    const Shop = usePage('Shop')
    const SnakeNLadder = usePage('SnakeNLadder')
    const StoreLocator = usePage('StoreLocator')
    const Subscription = usePage('Subscription')
    const Thankyou = usePage('Thankyou')
    const ViewCar = usePage('ViewCar')

    const AddApp = usePage('AddApp', 'admin')
    const AddBadge = usePage('AddBadge', 'admin')
    const AddCampaign = usePage('AddCampaign', 'admin')
    const AddCarModel = usePage('AddCarModel', 'admin')
    const AddEvent = usePage('AddEvent', 'admin')
    const AddFranchise = usePage('AddFranchise', 'admin')
    const AddGarage = usePage('AddGarage', 'admin')
    const AddJob = usePage('AddJob', 'admin')
    const AddMicroService = usePage('AddMicroService', 'admin')
    const AddNotification = usePage('AddNotification', 'admin')
    const AddOffer = usePage('AddOffer', 'admin')
    const AddPage = usePage('AddPage', 'admin')
    const AddPost = usePage('AddPost', 'admin')
    const AddProduct = usePage('AddProduct', 'admin')
    const AddProject = usePage('AddProject', 'admin')
    const AddService = usePage('AddService', 'admin')
    const AddSociety = usePage('AddSociety', 'admin')
    const AddTaxClass = usePage('AddTaxClass', 'admin')
    const AddTestimonial = usePage('AddTestimonial', 'admin')
    const AddUser = usePage('AddUser', 'admin')
    const AdminDashboard = usePage('AdminDashboard', 'admin')
    const AdminPinCode = usePage('AdminPinCode', 'admin')
    const AdminProjectList = usePage('AdminProjectList', 'admin')
    const AppBanners = usePage('AppBanners', 'admin')
    const AppList = usePage('AppList', 'admin')
    const BadgeList = usePage('BadgeList', 'admin')
    const CampaignList = usePage('CampaignList', 'admin')
    const CarListAdmin = usePage('CarListAdmin', 'admin')
    const CarModelList = usePage('CarModelList', 'admin')
    const CarModelManufacturerList = usePage('CarModelManufacturerList', 'admin')
    const AdminCity = usePage('AdminCity', 'admin')
    const CompanyWorkListAdmin = usePage('CompanyWorkListAdmin', 'admin')
    const ComplaintAdmin = usePage('ComplaintAdmin', 'admin')
    const EditUsersCarList = usePage('EditUsersCarList', 'admin')
    const EventsList = usePage('EventsList', 'admin')
    const AdminFAQ = usePage('AdminFAQ', 'admin')
    const FeedbackOrders = usePage('FeedbackOrders', 'admin')
    const FranchiseList = usePage('FranchiseList', 'admin')
    const FranchiseRequestAdmin = usePage('FranchiseRequestAdmin', 'admin')
    const GarageList = usePage('GarageList', 'admin')
    const GenericChatBot = usePage('GenericChatBot', 'admin')
    const ImportB2BStockData = usePage('ImportB2BStockData', 'admin')
    const ImportB2BWorkData = usePage('ImportB2BWorkData', 'admin')
    const ImportCars = usePage('ImportCars', 'admin')
    const ImportPinCode = usePage('ImportPinCode', 'admin')
    const ImportProducts = usePage('ImportProducts', 'admin')
    const InvoiceListAdmin = usePage('InvoiceListAdmin', 'admin')
    const JobsList = usePage('JobsList', 'admin')
    const KeyListAdmin = usePage('KeyListAdmin', 'admin')
    const LogList = usePage('LogList', 'admin')
    const Media = usePage('Media', 'admin')
    const MicroServiceCategory = usePage('MicroServiceCategory', 'admin')
    const MicroServiceList = usePage('MicroServiceList', 'admin')
    const ModuleList = usePage('ModuleList', 'admin')
    const AdminNotifications = usePage('AdminNotifications', 'admin')
    const OfferList = usePage('OfferList', 'admin')
    const OrderDetail = usePage('OrderDetail', 'admin')
    const Orders = usePage('Orders', 'admin')
    const PackageDetail = usePage('PackageDetail', 'admin')
    const PackageWorkListAdmin = usePage('PackageWorkListAdmin', 'admin')
    const PageList = usePage('PageList', 'admin')
    const PostList = usePage('PostList', 'admin')
    const ProductBrands = usePage('ProductBrands', 'admin')
    const ProductCategory = usePage('ProductCategory', 'admin')
    const ProductList = usePage('ProductList', 'admin')
    const PackageOrders = usePage('PackageOrders', 'admin')
    const ProductOrders = usePage('ProductOrders', 'admin')
    const QueriesChatBot = usePage('QueriesChatBot', 'admin')
    const Report = usePage('Report', 'admin')
    const Robots = usePage('Robots', 'admin')
    const SendMail = usePage('SendMail', 'admin')
    const ServiceCategory = usePage('ServiceCategory', 'admin')
    const ServiceList = usePage('ServiceList', 'admin')
    const ServiceOrders = usePage('ServiceOrders', 'admin')
    const ServiceWorkListAdmin = usePage('ServiceWorkListAdmin', 'admin')
    const Settings = usePage('Settings', 'admin')
    const Societies = usePage('Societies', 'admin')
    const AdminState = usePage('AdminState', 'admin')
    const TaxClassList = usePage('TaxClassList', 'admin')
    const TestimonialList = usePage('TestimonialList', 'admin')
    const Users = usePage('Users', 'admin')
    const WebBanners = usePage('WebBanners', 'admin')
    const WorkListAdmin = usePage('WorkListAdmin', 'admin')

    const Game = useRender('Game')
    const Admin = useRender('Admin')
    const Public = useRender('Public')
    const PreLogin = useRender('PreLogin')
    const PostLogin = useRender('PostLogin')
    const Business = useTemplate('Business')
    const Event = useTemplate('Event')
    const Franchise = useTemplate('Franchise')
    const Garage = useTemplate('Garage')
    const Job = useTemplate('Job')
    const Page = useTemplate('Page')
    const Post = useTemplate('Post')
    const Product = useTemplate('Product')
    const Project = useTemplate('Project')
    const Service = useTemplate('Service')
    const pageComponents = {
        "AddFranchise": <AddFranchise />,
        "FranchiseList": <FranchiseList />,
        "PackageWorkListAdmin": <PackageWorkListAdmin />,
        "ServiceWorkListAdmin": <ServiceWorkListAdmin />,
        "CompanyWorkListAdmin": <CompanyWorkListAdmin />,
        "ImportCars": <ImportCars />,
        "ImportPinCode": <ImportPinCode />,
        "ImportB2BWorkData": <ImportB2BWorkData />,
        "ImportB2BStockData": <ImportB2BStockData />,
        "Login": <Login />,
        "Otp": <Otp />,
        "Register": <Register />,
        "Home": <Home />,
        "Chat": <Chat />,
        "Contact": <Contact />,
        "Shop": <Shop />,
        "Thankyou": <Thankyou />,
        "Services": <Services />,
        "Cart": <Cart />,
        "ModuleList": <ModuleList />,
        "Subscription": <Subscription />,
        "Payment": <Payment />,
        "Ludo": <Ludo />,
        "SnakeNLadder": <SnakeNLadder />,
        "About": <About />,
        "Feedback": <Feedback />,
        "Complaint": <Complaint />,
        "Blog": <Blog />,
        "ProjectList": <ProjectList />,
        "AdminProjectList": <AdminProjectList />,
        "AddProject": <AddProject />,
        "OurEvents": <OurEvents />,
        "OrderTracking": <OrderTracking />,
        "Faq": <Faq />,
        "MicroServices": <MicroServices />,
        "Checkout": <Checkout />,
        "Offers": <Offers />,
        "ManageAddress": <ManageAddress />,
        "AddAddress": <AddAddress />,
        "Packages": <Packages />,
        "Profile": <Profile />,
        "CompanyProfile": <CompanyProfile />,
        "DashBoard": <DashBoard />,
        "MyOrders": <MyOrders />,
        "CompanyManagers": <CompanyManagers />,
        "MySubscription": <MySubscription />,
        "MyPackages": <MyPackages />,
        "MyPackageDetail": <MyPackageDetail />,
        "MyOrderDetail": <MyOrderDetail />,
        "ViewCar": <ViewCar />,
        "FranchiseWithUs": <FranchiseWithUs />,
        "Notifications": <Notifications />,
        "CarList": <CarList />,
        "AddManager": <AddManager/>,
        "AddCar": <AddCar/>,
        "AdminDashboard": <AdminDashboard />,
        "CompanyDashboard": <CompanyDashboard />,
        "CompanyWorkDashboard": <CompanyWorkDashboard />,
        "CompanyWorkDetailDashboard": <CompanyWorkDetailDashboard />,
        "CompanyStockDetailDashboard": <CompanyStockDetailDashboard />,
        "Report": <Report />,
        "Settings": <Settings />,
        "Media": <Media />,
        "AppBanners": <AppBanners />,
        "ComplaintAdmin": <ComplaintAdmin />,
        "FranchiseRequestAdmin": <FranchiseRequestAdmin />,
        "WebBanners": <WebBanners />,
        "AddUser": <AddUser />,
        "Societies": <Societies />,
        "AddSociety": <AddSociety />,
        "Orders": <Orders />,
        "ProductOrders": <ProductOrders />,
        "ServiceOrders": <ServiceOrders />,
        "PackageOrders": <PackageOrders />,
        "FeedbackOrders": <FeedbackOrders />,
        "PackageDetail": <PackageDetail />,
        "OrderDetail": <OrderDetail />,
        "AdminNotifications": <AdminNotifications />,
        "AddGarage": <AddGarage />,
        "AddApp": <AddApp />,
        "AddPost": <AddPost />,
        "AddJob": <AddJob />,
        "TaxClassList": <TaxClassList />,
        "AddTaxClass": <AddTaxClass />,
        "EditUsersCarList": <EditUsersCarList />,
        "CarListAdmin": <CarListAdmin />,
        "WorkListAdmin": <WorkListAdmin />,
        "InvoiceListAdmin": <InvoiceListAdmin />,
        "LogList": <LogList />,
        "KeyListAdmin": <KeyListAdmin />,
        "AddEvent": <AddEvent />,
        "SendMail": <SendMail />,
        "ProductList": <ProductList />,
        "BadgeList": <BadgeList />,
        "ImportProducts": <ImportProducts />,
        "ProductCategory": <ProductCategory />,
        "ProductBrands": <ProductBrands />,
        "AddProduct": <AddProduct />,
        "AddBadge": <AddBadge />,
        "ServiceCategory": <ServiceCategory />,
        "MicroServiceCategory": <MicroServiceCategory />,
        "AddMicroService": <AddMicroService />,
        "AddService": <AddService />,
        "GenericChatBot": <GenericChatBot />,
        "AdminFAQ": <AdminFAQ />,
        "AdminPinCode": <AdminPinCode />,
        "AdminCity": <AdminCity />,
        "AdminState": <AdminState />,
        "QueriesChatBot": <QueriesChatBot />,
        "Robots": <Robots />,
        "AddNotification": <AddNotification />,
        "AddTestimonial": <AddTestimonial />,
        "TestimonialList": <TestimonialList />,
        "AddCampaign": <AddCampaign />,
        "CampaignList": <CampaignList />,
        "AddOffer": <AddOffer />,
        "AddPage": <AddPage />,
        "PostList": <PostList />,
        "AppList": <AppList />,
        "JobsList": <JobsList />,
        "GarageList": <GarageList />,
        "PageList": <PageList />,
        "ServiceList": <ServiceList />,
        "OfferList": <OfferList />,
        "EventsList": <EventsList />,
        "MicroServiceList": <MicroServiceList />,
        "Users": <Users />,
        "CarModelManufacturerList": <CarModelManufacturerList />,
        "CarModelList": <CarModelList />,
        "AddCarModel": <AddCarModel />,
        "Run": <Run />,
        "StoreLocator": <StoreLocator />,
        "Project": <Project />,
        "Product": <Product />,
        "Post": <Post />,
        "Franchise": <Franchise />,
        "Garage": <Garage />,
        "Event": <Event />,
        "Job": <Job />,
        "Business": <Business />,
        "Page": <Page />,
        "Service": <Service />,
    }
    const {
        showPreloader,
        isAdminUrl,
        isModuleEnabled,
        hidePreloader,
    } = useService('Misc')
    const {
        getLoggedInUserData,
        isUserLoggedIn,
        isAllowedAdmin,
    } = useService('User')
    const activePath = activeRoute.replace(/\/$/, '');

    useEffect(() => {
        const popstate = window.addEventListener('popstate', () => {
            navigateTo({route: activePath})
        })
        const pushstate = window.addEventListener('pushstate', () => {
            navigateTo({route: activePath})
        })
        return () => {
            window.removeEventListener(popstate)
            window.removeEventListener(pushstate)
        }
        // window.addEventListener('hashchange', event => {
        //     navigateTo({route: activePath})
        // })

    }, [])

    let data = '',
    routeFound = false
    showPreloader()

    if (isAdminUrl(activeRoute)) {
        require('../../../../assets/scss/admin/styles.scss')
    } else {
        document.getElementById('root').classList = 'front'
        require('../../../../assets/scss/styles.scss')
    }
    
    const authorizationCheck = e => {
        let data = '', routeFound = true, user = getLoggedInUserData()
        if (['admin', 'developer'].includes(user?.role) || e?.type === 'public') {
            routeFound = true
        } else {
            if (e.rejectRoles)
                routeFound = isUserLoggedIn() && !e.rejectRoles.includes(user.role)
            if (e?.type === 'preLogin') {
                if (isAllowedAdmin()) {
                    navigateTo({route: '/admin/dashboard'})
                }
                if (isUserLoggedIn()) navigateTo({route: '/user/profile'})
            } else if (e?.type === 'postLogin' && !isUserLoggedIn())
                navigateTo({route: '/login'})
            else if (isUserLoggedIn() && e.accessRoles)
                routeFound = isUserLoggedIn() && e.accessRoles.includes(user.role)
        }
        if (e.redirectTo)
            navigateTo({route: e.redirectTo})
        else
            data = cloneElement( pageComponents[e.component], e );
        return { data, routeFound }
    }
    
    [...routes, ...subRoutes].forEach(e => {
        if(!e.isNewRoot) {
            subRoutes.forEach(el => {
                if (el.pathName === e.pathName)
                    e = el
            })
        }
        if ((e.mode === "static" && activePath === e.pathName) || (e.mode === "dynamic" && activePath.substring(0, activePath.lastIndexOf('/')) === e.pathName)) {
            let c = authorizationCheck(e)
            data = c?.data
            if (c?.routeFound)
                routeFound = c.routeFound
        }
    })
    if (!routeFound || !(isModuleEnabled(data.props?.module)))
        data = <ErrorPage />
    setTimeout(() => hidePreloader(), 500)
    if (data.props?.type === 'admin' && !isAllowedAdmin()) {
        localStorage.setItem('tempRedirectUrl', activeRoute);
        navigateTo({route: '/user/profile'})
    } else if (data.props?.type === 'postLogin' && !isUserLoggedIn()) {
        localStorage.setItem('tempRedirectUrl', activeRoute);
        navigateTo({route: '/login'})
    } else if (data.props?.type === 'preLogin' && isUserLoggedIn()) {
        navigateTo({route: '/user/profile'})
    } else {
        if (data.props?.type !== 'preLogin') {
            localStorage.removeItem('tempRedirectUrl');
        }
        switch (data.props?.type) {
            case 'game':
                return Game(data)
            case 'admin':
                return Admin(data)
            case 'preLogin':
                return PreLogin(data)
            case 'public':
                return Public(data)
            case 'postLogin':
                return PostLogin(data)
            default:
                return Public(data)
        }
    }
}
