import Select from 'react-select'
import {CSVLink} from 'react-csv'
import { fuelOption } from '../../../constant'
import { useCarListAdmin } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const CarListAdmin = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        setdisplayedRecommendationsList,
        setactiveItem,
        selectRecommendation,
        trackScrolling,
        title,
        filteredList,
        exportedData,
        activeOwnerId,
        owner,
        setowner,
        user,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
        closeRecommendationModal,
        filteredPostList,
        uploadFiles,
        closeWorkDataModal,
        searchPost,
        searchPostKey,
        workPostList,
        displayedRecommendationsList,
        activeCar,
        selectFiles,
        fileArray,
        setfileArray,
        comments,
        setcomments,
        fuelQuantity,
        setfuelQuantity,
        fuelType,
        setfuelType,
        activeItem,
    } = useCarListAdmin()
    const exportedFileName = 'exported_cars_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
    
    const renderRecommendations = recData => {
        return recData.map(( item, index ) => {
            return <li key={index} className='flex mb10'>
                {
                    item.image?
                    <>
                        <Img src={JSON.parse(item.image)[0]} alt={item.title} style={{ width: 50, borderRadius: 5, marginRight: 5 }} />
                        <span>{ item.title}{item.fuelQuantity && item.fuelType?.value && ' ('+item.fuelQuantity+' lt. '+item.fuelType.value+')'}</span>
                    </>:item.value
                }
                <i className="trash mlauto pointer hi-trash" onClick={() => {
                    setdisplayedRecommendationsList(recData.filter(e => e.uid !== item.uid))
                }}></i>
            </li>
        })
    }
    
    const renderFilteredList = filteredList => {
        return filteredList.map(( item, index ) => {
            let productimg = [], img = item.image ? JSON.parse(item.image): []
            img.forEach(e => {
                productimg.push(e)
            })
            return <li key={index} className="blog-product-search-box pointer" onClick={() => {
                if (item.sku?.includes("fuel-refill")) {
                    setactiveItem(item)
                    openModal('fuel-modal')
                } else {
                    selectRecommendation(item)
                }
            }}>
                {item.image ? <div className="flex">
                    <Img className="blog-product-search-img" src={
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        'product-placeholder.png':
                        productimg[0]} alt={item.title}
                    />
                    <div className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                    </div>
                </div>:<div className='fixed-service'>{item.value}</div>}
            </li>
        })
    }

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">
            {title}
            <Link className="btn btn-outline btn-small ml20" href="/admin/import/car">Import</Link>
            {
                filteredList.length !== 0 &&
                <span className="btn btn-primary btn-small ml20 export-btn">
                    <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                </span>
            }
        </h1>
        <div className="filter-box">
            {user && !activeOwnerId && <div className="filters relative">
                <label className="filter-label-add">Owner: </label>
                <Select
                    value={owner}
                    defaultValue={owner}
                    onChange={owner => setowner(owner)}
                    options={user}
                />
            </div>}
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashEvent} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal" id="recommendation-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <Button className="close" onClick={closeRecommendationModal}><i className="hi-close"></i></Button>
                {activeCar && <div className="modal-body p20">
                    <h3 className='lh1'>Search to recommend</h3>
                    <div className='widget-box'>
                        <div className="form-group">
                            <Input required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={searchPost} className="form-control" />
                        </div>
                        <div className="search-result-blog">
                            <ul>
                                {
                                    searchPostKey !== ''?
                                    renderFilteredList(filteredPostList || []) : null
                                }
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <h3 className='lh1'>Recommendations List</h3>
                    <ul>
                        { renderRecommendations(displayedRecommendationsList) }
                    </ul>
                    <hr />
                    <h3 className='lh1'>Car Details</h3>
                    <div className='flex'>
                        {
                            activeCar?.carData?.image_path ?
                            <Img source='carCDN' src={activeCar?.carData?.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                            <p>No Image</p>
                        }
                        <div className='fs12'>
                            {activeCar?.registrationNo?.toUpperCase()}<br />
                            {activeCar?.carData?.name+' '+activeCar?.carData?.Segment}
                        </div>
                    </div>
                    <h3>Recent Car Images</h3>
                    {
                        fileArray.length < 5 &&
                        <>
                            <label htmlFor="choose-file" className="btn btn-primary btn-small">Car Images</label>
                            <Input type="file" className="hidden" onChange={selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                        </>
                    }
                    {
                        fileArray.length !== 0 &&
                        <div className="upload-box">
                            <ul className="media-box mb20">
                                {(fileArray || []).map((file, index ) => (
                                    <li key={index}>
                                        <Img source="upload" src={file.url} alt="..." />
                                    </li>
                                ))}
                            </ul>
                            {fileArray.length > 0 && <Button className="btn-outline btn btn-small" onClick={() => setfileArray([])}>Reset</Button>}
                        </div>
                    }
                    <hr />
                    <h3 className='lh1'>Comments</h3>
                    <textarea className="comments" placeholder="Type your comments here" required name="comments" value={comments} onChange={e => setcomments(e.target.value)}></textarea>
                    <Button className="mb0 btn btn-success btn-box" onClick={uploadFiles}>Submit</Button>
                </div>}
            </div>
        </div>
        <div className="modal" id="workdata-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <Button className="close" onClick={closeWorkDataModal}><i className="hi-close"></i></Button>
                {activeCar && <div className="modal-body p20">
                    <h3 className='lh1'>Search service name</h3>
                    <div className='widget-box'>
                        <div className="form-group mb0">
                            <Input required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={searchPost} className="form-control" />
                        </div>
                        <div className="search-result-blog">
                            <ul>
                                {
                                    searchPostKey !== ''?
                                    renderFilteredList(workPostList || []) : null
                                }
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <h3 className='lh1'>Services List</h3>
                    <ul>
                        { renderRecommendations(displayedRecommendationsList) }
                    </ul>
                    <hr />
                    <h3 className='lh1'>Car Details</h3>
                    <div className='flex'>
                        {
                            activeCar?.carData?.image_path ?
                            <Img source='carCDN' src={activeCar?.carData?.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                            <p>No Image</p>
                        }
                        <div className='fs12'>
                            {activeCar?.registrationNo?.toUpperCase()}<br />
                            {activeCar?.carData?.name+' '+activeCar?.carData?.Segment}
                        </div>
                    </div>
                    <h3>Post Work Car Images</h3>
                    {
                        fileArray.length < 5 &&
                        <>
                            <label htmlFor="choose-file" className="btn btn-primary btn-small">Car Images</label>
                            <Input type="file" className="hidden" onChange={selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                        </>
                    }
                    {
                        fileArray.length !== 0 &&
                        <div className="upload-box">
                            <ul className="media-box mb20">
                                {(fileArray || []).map((file, index ) => (
                                    <li key={index}>
                                        <Img source="upload" src={file.url} alt="..." />
                                    </li>
                                ))}
                            </ul>
                            {fileArray.length > 0 && <Button className="btn-outline btn btn-small" onClick={() => setfileArray([])}>Reset</Button>}
                        </div>
                    }
                    <hr />
                    <h3 className='lh1'>Comments</h3>
                    <textarea className="comments" placeholder="Type your comments here" required name="comments" value={comments} onChange={e => setcomments(e.target.value)}></textarea>
                    <Button className="mb0 btn btn-success btn-box" onClick={uploadFiles}>Submit</Button>
                </div>}
            </div>
        </div>
        <div className="modal" id="fuel-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <Button className="close" onClick={() => closeModal('fuel-modal')}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <div className='box'>
                        <h4 className="head">Fuel Capacity <small>(in Liters)</small></h4>
                        <div className='body overflown'>
                            <Input name="fuelQuantity" value={fuelQuantity} onChange={e => setfuelQuantity(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                        <div className="col-9 controls">
                            <Select
                                value={fuelType}
                                defaultValue={fuelType}
                                onChange={e => setfuelType(e)}
                                options={fuelOption}
                            />
                        </div>
                    </div>
                    <Button className="btn btn-success btn-box mauto" onClick={() => selectRecommendation(activeItem)}>Submit</Button>
                </div>
            </div>
        </div>
    </div>
}
