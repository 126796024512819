import { useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useQueriesChatBot = () => {
    const Actor = useElement('Actor')
    const {commonPageSize} = config
    const {
        fetchAll,
        remove,
        save,
        update,
    } = useService('Data', 'chat')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        generateUID,
        closeModal,
        showAlert,
        openModal,
    } = useService('Misc')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeItem, setactiveItem] = useState(null)
    const [chatList, setchatList] = useState([])
    const [question, setquestion] = useState('')
    const [answer, setanswer] = useState('')
    const [uid, setuid] = useState('')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [mode, setmode] = useState('Add')
    const [filteredList, setfilteredList] = useState([])
    const [tableContent, settableContent] = useState({})

    useEffect(() => {
        setTitle('Queries Chat', 'admin')
        getChatsData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getChatsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No chats placed yet!' })
                setchatList([])
            } else {
                let chatList = []
                result.data.forEach(e => {
                    if (e.type === 'queries')
                        chatList.push(e)
                })
                setchatList(chatList)
            }
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashChat = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await remove(query)
        if (result.status) {
            getChatsData()
            showAlert({ type: 'success', msg: 'Chat deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete chat!' })
    }
    
    const addChat = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        chatObject = {
            question,
            answer,
            type: 'queries'
        }
        if (question === '' || answer === '') {
            errorMsg = 'Please enter chat content!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                chatObject.uid = generateUID()
                const result = await save(chatObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Chat added successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add chat!' })
                }
                setquestion('')
                setanswer('')
                setuid('')
                closeModal("chat-modal")
                getChatsData()
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    chatData: JSON.stringify(chatObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Chat updated successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update chat!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                content: <div style={{paddingBottom: 40}}>
                    <p className="query"><b>Q: </b>{item.question}</p>
                    <p className="ans"><b>A: </b>{item.answer}</p>
                </div>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        setmode('Edit')
                        setquestion(item.question)
                        setanswer(item.answer)
                        setuid(item.uid)
                        openModal("chat-modal")
                    }} />
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Queries',
                    key: 'queries'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(chatList)
        })
    }, [chatList])
    return {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashChat,
        trashCancel,
        showTrashConfirm,
        setanswer,
        setuid,
        mode,
        addChat,
        question,
        setquestion,
        answer,
    }
}