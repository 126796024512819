import { useEffect, useState } from "react"
import { base } from "../../../constant"
import { useService } from "../../../hooks"

export const useFeaturedProduct = () => {
    const {
        toIndianCurrency,
        showAlert,
        generateUID,
    } = useService('Misc')
    const {addProductToCart} = useService('Order')
    const {getProduct} = useService('Product')
    const [featuredProducts, setfeaturedProducts] = useState([])

    const getProductsData = async () => {
        const result = await getProduct({isFeatured: 'true'})
        if (result.status) {
            let tableData = [],
            count = 0;
            result.data.reverse().forEach(e => {
                if(e.status === 'publish' && e.isFeatured) {
                    if (typeof e.trash === "undefined" && e.isFeatured && count < 40) {
                        tableData.push(e)
                        count++;
                    } else if (!e.trash && count<40) {
                        tableData.push(e)
                        count++;
                    }
                }
            })
            setfeaturedProducts(tableData)
        }
    }
    const productAddedTemplate = item => {
        let price = item.price
        if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp ) {
            price = item.saleprice
        }
        let prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Product added to cart!</p></div>'+
        '<div class="product-box">'+
        '<Img src="'+base.imageBaseUrl+item.image+'" alt="'+item.title+'" class="product-image" />'+
        '<div class="product-content">'+
        '<p class="product-title">'+item.title+'</p>'+
        '<p class="product-price">'+toIndianCurrency(price)+'</p>'+
        '</div>'+
        '</div>'
        showAlert({ type: 'custom', template: prodcutAddedTemplate })
    }
    
    const addToCart = item => {
        let price = item.price, mrp = price, saleprice = item.saleprice
        if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp ) {
            price = saleprice
        }
        let product = {
            uid: item.uid,
            title: item.title,
            image: JSON.parse(item.image)[0],
            purchaseQuantity: 1,
            type: 'product',
            tempUid: generateUID(),
            mrp: Number(mrp),
            saleprice,
            tax: item.tax,
            hsn: item.hsn,
            sku: item.sku,
            taxAmount: Number((price * Number(item.tax)/(100+Number(item.tax))).toFixed(2))
        };
        product.displayPrice = price
        product.price = price - product.taxAmount
        addProductToCart(product)
        productAddedTemplate(product)
    }

    useEffect(() => {
        getProductsData()
    }, [])
    return {
        toIndianCurrency,
        featuredProducts,
        addToCart,
    }
}