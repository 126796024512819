import { useCallback, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useAdminCity = () => {
    const {
        showPreloader,
        hidePreloader,
        showAlert,
        generateUID,
        closeModal,
        openModal,
    } = useService('Misc')
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {
        fetchAll,
        remove,
        save,
        update,
    } = useService('Data', 'city')
    const {
        fetchAll: fetchAllStates,
    } = useService('Data', 'state')
    const {setTitle} = useService('Meta')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeState, setactiveState] = useState(null)
    const [states, setstates] = useState(null)
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [cityList, setcityList] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [mode, setmode] = useState('Add')
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('City', 'admin')
        getCitysData()
        getStatesData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getStatesData = async () => {
        const result = await fetchAllStates()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No State added yet!' })
            else {
                const statesList = []
                result.data.forEach(e => {
                    let el = {
                        value: e.code,
                        label: e.name,
                    }
                    statesList.push(el)
                })
                setstates(statesList)
            }
        }
    }

    const assignState = useCallback((e) => {
        setactiveState(e)
        setactiveItem({...activeItem, state: e.value})
    }, [])

    const getCitysData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No City added yet!' })
            } else {
                setcityList(result.data)
            }
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashCity = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getCitysData()
            showAlert({ type: 'success', msg: 'City deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete city!' })
    }
    
    const addCity = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        cityObject = {
            name: activeItem.name,
            status: activeItem.status,
            code: activeItem.code,
            state: activeItem.state,
        }
        if (cityObject.name === '' ) {
            errorMsg = 'Please enter Name!'
            error = true
        } else if (cityObject.code === '') {
            errorMsg = 'Please enter City'
            error = true
        } else if (cityObject.state === '') {
            errorMsg = 'Please enter State'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                cityObject.uid = generateUID()
                const result = await save(cityObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'City added successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add city!' })
                }
            } else {
                delete cityObject._id
                let data = {
                    query: JSON.stringify({uid: cityObject.uid}),
                    cityData: JSON.stringify(cityObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'City updated successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update city!' })
                }
            }
            setactiveItem(null)
            closeModal("city-modal")
            getCitysData()
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                name: item.name,
                code: item.code,
                state: item.state,
                status: <Badge item={item.status ? 'active' : 'inactive'} />,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        setmode('Edit')
                        setactiveItem(item)
                        openModal("city-modal")
                    }} />
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'State',
                    key: 'state'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(cityList)
        })
    }, [cityList])

    const openAddModal = () => {
        setmode('Add')
        setactiveItem({
            code: '',
            status: false,
            state: '',
            name: '',
            area: ''
        })
        openModal("city-modal")
    }
    return {
        trackScrolling,
        openAddModal,
        tableContent,
        activeShownTotal,
        trashCity,
        trashCancel,
        showTrashConfirm,
        mode,
        closeModal,
        addCity,
        activeItem,
        setactiveItem,
        activeState,
        assignState,
        states,
    }
}