import { useContext, useEffect, useState } from "react"
import { config, searchRegex } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const usePackageWorkListAdmin = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchAll} = useService('Data', 'packageWork')
    const {
        showPreloader,
        hidePreloader,
        getFormattedDate,
    } = useService('Misc')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [searchKey, setsearchKey] = useState('')
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const search = e => {
        const key = e.target.value
        if (key === '' || searchRegex.test(key)) {
            setsearchKey(key);
        }
        let tempTitle = '', fList = []
        tableData.forEach(e => {
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                fList.push(e)
        })
        setfilteredList(fList)
    }
    
    // useEffect(() => {
    //     setcheckedAll(filteredList.length === checkedList.length)
    // }, [filteredList, checkedList])

    // const toggleCheck = uid => {
    //     if (checkedList.includes(uid)) {
    //         setcheckedList(checkedList.filter(e => e !== uid))
    //     } else {
    //         setcheckedList([...checkedList, uid])
    //     }
    // }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                // checkAction: <span className='check-all' onClick={() => toggleCheck(item.uid)}>
                //     {
                //         checkedAll || checkedList.includes(item.uid) ?
                //         <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                //     }
                // </span>,
                // title: item.title,
                // image: item.image?
                //     <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                // <p>No Image</p>,
                // releaseDate: getFormattedDate(new Date(item.releaseDate)),
                // status: item.status,
                // action: trashAction?
                // <div className="action-bar">
                //     <Actor type='trash' onClick={() => setState({ activeItem: item, showTrashConfirm: true })} />
                //     <Actor type='undo' onClick={() => remove(item.uid, 'restore')} />
                // </div>:
                // <div className="action-bar">
                //     <Actor type='trash' onClick={() => setState({ activeItem: item, showTrashConfirm: true })} />
                //     <Actor type='view' onClick={() => navigateTo({route: '/project/'+item.slug})} />
                //     <Actor type='edit' onClick={() => navigateTo({route: '/admin/project/edit/'+item.uid})} />
                // </div>
            }
            data.push(e)
        })
        return data
    }
    
    const fetch = async () => {
        const result = await fetchAll()
        if (result.status) {
            let td = result.data.reverse()
            settableData(td)
            setfilteredList(td)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        settableContent({
            headers: [],
            content: getTableData(filteredList)
        })
    }, [filteredList])
    return {
        trackScrolling,
        searchKey,
        search,
        tableContent,
        activeShownTotal,
    }
}