import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { NavigatorContext } from '../../../../context'

export const useEventList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {isValidJson} = useService('Validation')
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const Img = useElement('Img')
    const {
        fetchAll,
        remove,
        update,
        save,
    } = useService('Data', 'event')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        showAlert,
        generateUID,
        getFormattedDate,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const [tableContent, settableContent] = useState({})
    const [tableData, settableData] = useState([])
    const [image, setimage] = useState('')
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedAll, setcheckedAll] = useState(false)
    const [trashAction, settrashAction] = useState(false)
    const [activeItem, setactiveItem] = useState(null)
    const [searchKey, setsearchKey] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Events List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getEventsListData()
    }, [])

    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getEventsListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                e.image = isValidJson(e.image) ? JSON.parse(e.image) : null
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No event added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No event added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectEvent = () => {
        deleteEvent(activeItem.uid)
    }
    
    const deleteEvent = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'Event deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getEventsListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete event!' })
        } else {
            let eventObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    eventObject = e
            })
            eventObject.trash = task === 'trash'
            delete eventObject._id
            let data = {
                query: JSON.stringify({uid}),
                eventData: JSON.stringify(eventObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Event '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getEventsListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' event!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => deleteEvent(e.uid))
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const cloneEvent = async item => {
        let eventObject = { ...item, uid: generateUID() }
        const result = await save(eventObject)
        if (result.status)
            showAlert({ type: 'success', msg: 'Event clonsed successfully!' }, getEventsListData)
        else showAlert({ type: 'error', msg: 'Unable to add event!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>{
                    checkedAll || checkedList.includes(item.uid) ?
                    <i className="orange hi-check-circle"></i>
                    :<i className="hi-circle"></i>
                }</span>,
                title: item.title,
                image: item.image?
                <Img src={item.image[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                publishDate: getFormattedDate(new Date(item.publishDate)),
                status: <Badge item={item.status} />,
                action: trashAction ?
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                            setshowTrashConfirm(true)
                        }} />
                        <Actor type='undo' onClick={() => deleteEvent(item.uid, 'restore')} />
                    </div>:
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                            setshowTrashConfirm(true)
                        }} />
                        <Actor type='view' onClick={() => navigateTo({route: '/event/'+item.slug, newTab: true})} />
                        <Actor type='edit' onClick={() => navigateTo({route: '/admin/event/edit/'+item.uid})} />
                        <Actor type='copy' onClick={() => cloneEvent(item)} />
                    </div>
            }
            data.push(e)
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }

    useEffect(() => {
        settableContent({
            headers: [
                {template: <span className="check-all">
                    <span onClick={() => setcheckedAll(!checkedAll)}>
                        {
                            checkedAll ?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>
                </span>},
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Event Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectEvent,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}