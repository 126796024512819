import {brandInfo} from '../../../constant'
import { useAdminSidebar } from './hooks'
import { useElement, useService } from '../../../hooks'
import './style.scss'
export const AdminSidebar = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const {getLocationData} = useService('Router')
    const {
        toggleActiveClass,
        filter,
        user,
        adminNav,
        navFilter,
        setnavFilter,
    } = useAdminSidebar()

    const renderAdminSideBar = (adminNavList, navId = false) => {
        let activePath = getLocationData().pathname
        return adminNavList.map(( item, index ) => {
            let hasActiveChild = false
            if (item.subNav) {
                item.subNav.forEach(e => {
                    hasActiveChild = activePath === '/admin'+e.url
                })
            }
            if (navId)
                index = navId+'-'+index
            return <li key={index} className={((activePath === '/admin'+item.url) || hasActiveChild)?'active': ''} id={'nav-id-'+index}>
                {
                    ((activePath === item.url) || hasActiveChild) && <span className="upper-link"></span>
                }
                {
                    item.subNav ?
                    <Button onClick={toggleActiveClass} title={item.title}>
                        <i className={item.icon}></i>
                        <span>{item.label}</span>
                        <i className='hi-chevron-bottom'></i>
                    </Button>:
                    <Link href={'/admin'+item.url} title={item.title}>
                        <i className={item.icon}></i>
                        <span>{item.label}</span>
                    </Link>
                }
                {
                    item.subNav &&
                    <ul className="sub-nav">
                        {renderAdminSideBar(item.subNav || [], index )}
                    </ul>
                }
                {
                    ((activePath === item.url) || hasActiveChild) && <span className="bottom-link"></span>
                }
            </li>
        })
    }
    
    const renderAnywhereNav = (routes, parentIndex = false) => {
        return routes.map((item, index) => {
            return <li className='' key={ parentIndex ? parentIndex+'_'+index : index}>
                { filter(item.label) && <>{parentIndex ? <i className="hi-subdirectory_arrow_right"></i>: ''}</>}
                { filter(item.label) && <>{item.url ? <Link href={'/admin'+item.url}>
                    { item.label }
                </Link> : <span>{ item.label }</span>}</>}
                {item.subNav && <ul className='sub-nav'>
                    { renderAnywhereNav(item.subNav || [], index) }
                </ul>
                }
            </li>
        })
    }

    return <div className="sidebar">
        <Link href="/admin" className="logo-link">
            <Img alt="logo" source='internal' src={brandInfo.logoWhite} />
        </Link>
        {
            ['franchiseOwner', 'franchiseManager'].includes(user?.role) ? <span className='subtitle'>FRANCHISE</span>: ''
        }
        <nav className="sidebar-nav">
            <ul className="nav">
                <li id="nav-id-goto-0">
                    <Button onClick={() => openModal("anywhere-modal")} title='Go to...'>
                        <i className='hi-search'></i>
                        <span>Go to...</span>
                    </Button>
                </li>
                { renderAdminSideBar(adminNav) }
            </ul>
        </nav>
        <div className="modal" id="anywhere-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <div className="modal-header">
                    <h2 className="heading">
                        <i className="hi-search1"></i>
                        Go to...
                    </h2>
                    <Button className="close" onClick={() => closeModal("anywhere-modal")}><i className="hi-close"></i></Button>
                </div>
                <div className="modal-body p20">
                    <form className="search-form" noValidate onSubmit={e => e.preventDefault}>
                        <Input required name="searchNavKey" value={navFilter} onChange={e => setnavFilter(e.target.value)} />
                    </form>
                    <ul className='anywhere-nav'>
                        { renderAnywhereNav(adminNav) }
                    </ul>
                </div>
            </div>
        </div>
    </div>
}