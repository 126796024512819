import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { NavigatorContext } from '../../../../context'

export const usePackageOrders = () => {
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const {getLoggedInUserData} = useService('User')
    const user = getLoggedInUserData()
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        getFranchiseData,
        showAlert,
        toIndianCurrency,
        getFormattedDate,
        parseAddress,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {navigateTo} = useContext(NavigatorContext)
    const {
        getOrder,
        getOrders,
    } = useService('Order')
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [tableData, settableData] = useState([])
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [searchKey, setsearchKey] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Package Orders', 'admin')
        getOrdersData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }

    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getOrdersData = async () => {
        let result = ''
        if(['franchiseOwner', 'franchiseManager'].includes(user?.role)) {
            let query = {
                source: 'franchise',
                franchiseId: getFranchiseData().value
            }
            result = await getOrder(query)
        } else {
            result = await getOrders()
        }
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach((item, index) => {
                if (item.status !== 'initialised' && item.status !== 'payment-fail') {
                    let orderData = JSON.parse(item.orderData), packagesData = [], unpgradedPackages = [], newPackagesData = []
                    orderData.cartData.forEach(e => {
                        if (e.type === 'servicePackage') {
                            e.orderId = item.uid
                            e.orderData = item
                            if (e.previousPackageId) {
                                unpgradedPackages.push(e)
                            } else {
                                packagesData.push(e)
                            }
                            packagesData.forEach(el => {
                                unpgradedPackages.forEach(e => {
                                    if (e.previousPackageId === String(el.tempUid))
                                        el.title = e.title
                                })
                                newPackagesData.push(el)
                            })
                        }
                    })
                    tableData = [...tableData, ...newPackagesData]
                }
            })
            settableData(tableData)
            setfilteredList(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No order placed yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let orderTime = item?.orderData?.timing
            let startTime = startDate === ''?'':new Date(startDate)
            let endTime = endDate === ''?new Date():new Date(endDate)
            if (orderTime >= startTime && orderTime <= endTime) {
                let e = {
                    type: <div className="product-thumbnail">
                        {
                            item.type === 'servicePackage'?
                            <i className="hi-dropbox service-package-icon"></i>:
                            item.type === 'subscription'?
                            <i className="hi-block service-package-icon"></i>:
                            <Img src={item.image} alt={item.title} />
                        }
                    </div>,
                    title: <div className="product-name" data-title="Product">
                        {item.title}
                    </div>,
                    price: <div className="product-price" data-title="Price">
                        <span className="Price-amount amount">{toIndianCurrency(item.price)}</span>
                    </div>,
                    orderId: '#'+item.orderId,
                    name: JSON.parse(item.orderData.userData).name,
                    startDate: getFormattedDate(new Date(item.startDate)),
                    endDate: getFormattedDate(new Date(item.endDate)),
                    action: <div className="action-bar">
                        <Actor type='view' onClick={() => navigateTo({route: '/admin/package/order/view/' + btoa(item.orderId + '/' + item.tempUid), newTab: true})} />
                    </div>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let filteredList = [], tempTitle = ''
            tableData.forEach(e => {
                tempTitle = isValidJson(e.orderData.userData) ? (JSON.parse(e.orderData.userData).name).toLowerCase() : ''
                if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
            setactiveFilterIndex(0)
        }
    }
    
    useEffect(() => {
        let exportedData = []
        filteredList.forEach(e => {
            let orderData = e.orderData
            if (!orderData.discount) {
                orderData.discount = 0
            }
            if (typeof orderData.cartTotal !== "number")
                orderData.cartTotal = Number(orderData.cartTotal)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
            if (isValidJson(orderData.orderData)) {
                JSON.parse(orderData.orderData).cartData?.forEach(( c, i ) => {
                    c.tax = Number(c.tax);
                    c.price = Number(c.price).toFixed(2)
                    c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
                    if (c.type === 'servicePackage' && c.status !== 'initialised' && c.status !== 'payment-fail') {
                        let ex = {
                            Customer_Id: (i === 0) ? JSON.parse(e.orderData.userData).uid: '',
                            Order_Id: (i === 0) ? e.uid: '',
                            Customer_Name: (i === 0) ? JSON.parse(e.orderData.userData).name: '',
                            Mobile_No: (i === 0) ? JSON.parse(e.orderData.userData).mobile: '',
                            Address: (e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? parseAddress(JSON.parse(e.activeAddress)): ''): '',
                            Pin_Code: (e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
                            Status: (i === 0) ? e.status: '',
                            Vehicle_Details: c.activeCar ? c.activeCar.name + ' ' + c.activeCar.Segment: '',
                            Vehicle_No: c.activeCar ? c.activeCar.registrationNo?c.activeCar.registrationNo: '': '',
                            Package: c.type === 'servicePackage'?c.title: '',
                            Package_Frequency: c.billing? c.billing: '',
                            Start_Date: getFormattedDate(new Date(c.startDate)),
                            End_Date: getFormattedDate(new Date(c.endDate))
                        }
                        exportedData.push(ex)
                    }
                })
            }
        })
        setexportedData(exportedData)
        settableContent({
            headers: [
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, startDate, endDate])
    return {
        trackScrolling,
        filteredList,
        exportedData,
        startDate,
        setstartDate,
        endDate,
        setendDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
    }
}