import { useContext, useEffect, useState } from "react"
import { statusOption, config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const usePostList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const filterTags = statusOption
    const {
        fetchAll,
        remove,
        update,
        save,
    } = useService('Data', 'post')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        showAlert,
        generateUID,
        getFormattedDate,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [tableData, settableData] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [filteredList, setfilteredList] = useState([])
    const [searchKey, setsearchKey] = useState('')
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [trashAction, settrashAction] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedList, setcheckedList] = useState([])
    const [checkedAll, setcheckedAll] = useState(false)
    const [searchList, setsearchList] = useState([])
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Posts List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getPostListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getPostListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No post added yet!' })
        } else showAlert({ type: 'error', msg: 'No post added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectedPost = () => {
        deletePost(activeItem.uid)
    }
    
    const deletePost = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                getPostListData()
                showAlert({ type: 'success', msg: 'Post deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getPostListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete post!' })
        } else {
            let postObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    postObject = e
                }
            })
            postObject.trash = task === 'trash'
            delete postObject._id
            let data = {
                query: JSON.stringify({uid}),
                postData: JSON.stringify(postObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Post '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getPostListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' post!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            deletePost(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const clonePost = async item => {
        let postObject = {
            ...item,
            slug: item.slug+'-copy',
            uid: generateUID()
        }
        const result = await save(postObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Post clonsed successfully!' })
            getPostListData()
        } else showAlert({ type: 'error', msg: 'Unable to add post!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    <CheckBox isChecked={checkedAll || checkedList.includes(item.uid)} />
                </span>,
                title: item.title,
                image: item.image?
                    <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                publishDate: getFormattedDate(new Date(item.publishDate)),
                status: item.status,
                action: trashAction?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='undo' onClick={() => deletePost(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='view' onClick={() => navigateTo({route: '/post/'+item.slug})} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/post/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => clonePost(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Post Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedPost,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}