import { useCallback, useContext, useEffect, useState } from "react"
import { config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useProductList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const filterTags = require('../filterTags.json')
    const {commonPageSize} = config
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        generateUID,
        showAlert,
        isEmpty,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        addProduct,
        getProduct,
        getProducts,
        deleteProduct: removeProduct,
        updateProduct,
    } = useService('Product')
    const [tableContent, settableContent] = useState({})
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [tableData, settableData] = useState([])
    const [exportedData, setexportedData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [trashAction, settrashAction] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedAll, setcheckedAll] = useState(false)
    const [checkedList, setcheckedList] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [searchKey, setsearchKey] = useState('')
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const cloneProduct = async item => {
        let productObject = {
            ...item,
            slug: item.slug+'-copy',
            uid: generateUID()
        }
        const result = await addProduct(productObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Product clone successfully!' })
            getProductListData(trashAction)
        } else showAlert({ type: 'error', msg: 'Unable to clone product!' })
    }
    
    const getProductListData = async (trashAction) => {
        let tableData = [], result
        if (trashAction) {
            result = await getProduct({trash: true})
        } else {
            result = await getProducts()
        }
        if (result.status) {
            result.data.reverse().forEach(e => {
                if(trashAction) {
                    tableData.push(e)
                } else {
                    if (typeof e.trash === "undefined" || !e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1)
                    activefilterValue = e.value
            })
            filterItems( activefilterValue, activeFilterIndex, tableData )
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No product added yet!' })
        } else showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectProduct = () => {
        deleteProduct(activeItem.uid)
    }
    
    const deleteProduct = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await removeProduct({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'Product deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getProductListData(trashAction)
            } else showAlert({ type: 'error', msg: 'Unable to delete product!' })
        } else {
            let productObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    productObject = e
            })
            productObject.trash = task === 'trash'
            delete productObject._id
            let data = {
                query: JSON.stringify({uid}),
                productData: JSON.stringify(productObject)
            }
            const result = await updateProduct(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Product '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getProductListData(trashAction)
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' product!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    <CheckBox isChecked={checkedAll || checkedList.includes(item.uid)} />
                </span>,
                title: item.title,
                image: item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                    <Img src={'product-placeholder.png'} alt={item.title} style={{maxHeight: 40, maxWidth: 40}} />:
                    <Img src={JSON.parse(item.image)[0]} alt={item.title} style={{maxHeight: 40, maxWidth: 40}} />,
                price: item.price,
                status: item.status,
                action: trashAction ?
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                            setshowTrashConfirm(true)
                        }} />
                        <Actor type='undo' onClick={() => deleteProduct(item.uid, 'restore')} />
                    </div>:
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                            setshowTrashConfirm(true)
                        }} />
                        {item.status === "publish" && <Actor type='view' onClick={() => navigateTo({route: '/product/'+item.uid})} />}
                        <Actor type='edit' onClick={() => navigateTo({route: '/admin/product/edit/'+item.uid})} />
                        <Actor type='copy' onClick={() => cloneProduct(item)} />
                    </div>
            }
            data.push(e)
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    const operate = (str) => {
        str = str.replace(/,/g,'\,').replace(/"/g,"'")
        return str
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const deleteChecked = () => {
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            deleteProduct(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }

    const toggleCheckAll = useCallback(() => {
        let newCheckedList = []
        if (checkedAll) {
            setcheckedList([])
        } else {
            filteredList.forEach(e => newCheckedList.push(e.uid))
            setcheckedList(newCheckedList)
        }
        setcheckedAll(!checkedAll)
    }, [])

    useEffect(() => {
        setTitle('Products List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getProductListData(urlData[2] === 'trash')
    }, [])

    useEffect(() => {
        let exportedData = []
        tableData.forEach(e => {
            let categoryList = '', categoryArray = !isEmpty(e.category) ? JSON.parse(e.category): []
            if (categoryArray === '')
                categoryList = '[]'
            else {
                categoryArray.forEach((ec, index ) => {
                    if (index === 0)
                        categoryList += '['
                    categoryList += "'"+ec.label+"'"
                    if (index+1 !== categoryArray.length)
                        categoryList += '\, '
                    else
                        categoryList += ']'
                })
            }
            let imagesList = '', imagesArray = JSON.parse(e.image)
            imagesArray.forEach((ei, index ) => {
                if (index === 0) {
                    imagesList += '['
                }
                imagesList += "'"+ei+"'"
                if (index+1 !== imagesArray.length) {
                    imagesList += '\, '
                } else {
                    imagesList += ']'
                }
            })
            let ex = {
                Item_Code: e.sku,
                Title: e.title,
                Brand: e.brand && e.brand !== 'null' && typeof e.brand && e.brand !== 'undefined' ? JSON.parse(e.brand).label: '',
                MRP: e.price,
                Sale_Price: e.saleprice,
                IGST_Rate: e.tax,
                HS_Code: e.hsn,
                Stock_Availability: e.stock,
                IsFeatured: e.isFeatured?e.isFeatured: 'FALSE',
                Category: categoryList,
                Image_Path: imagesList,
                Excerpt: operate(e.excerpt),
                Description: operate(e.content)
            }
            exportedData.push(ex)
        })
        setexportedData(exportedData)
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={toggleCheckAll}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Price',
                    key: 'price'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        filteredList,
        exportedData,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        search,
        searchKey,
        resetSearch,
        tableContent,
        isMultipleDelete,
        deleteChecked,
        deleteSelectProduct,
        trashCancel,
        activeShownTotal,
        showTrashConfirm,
        filterTags,
    }
}