import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useStoreLocator = () => {
    const {fetchByAttr} = useService('Data', 'franchise')
    const commonPageSize = 20
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useService('Misc')
    const [cities, setcities] = useState([{
        label: 'All',
        value: 'all'
    }])
    const [activeCity, setactiveCity] = useState(cities[0])
    const [tableData, settableData] = useState([])
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('CarMitra Studios')
        getFranchisesData()
        document.addEventListener('scroll', trackScrolling)
        return () => {
            document.removeEventListener('scroll', trackScrolling)
        }
    } ,[])

    const assignCity = e => {
        setactiveCity(e)
        if (e.value === 'all') {
            setfilteredList(tableData)
        } else {
            filterItems(e.value, tableData)
        }
    }

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getFranchisesData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            let tableData = [], cities = [{
                label: 'All',
                value: 'all'
            }]
            await result.data.reverse().filter(e => {
                e.addressDetail = JSON.parse(e.addressDetail)
                e.owner = JSON.parse(e.owner)
                e.contactPerson = JSON.parse(e.contactPerson)
                e.addressDetail.address_components?.forEach(l => {
                    if (l.types?.includes("administrative_area_level_2")) {
                        e.addressDetail.city = l.long_name
                    }
                    if (l.types?.includes("sublocality_level_1")) {
                        e.addressDetail.area = l.long_name
                    }
                })
                e.places = []
                e.places.push(e.addressDetail.geometry.location)
                if (!e.trash)
                    tableData.push(e)
                let cityFound = false
                cities.forEach(l => {
                    if (l.value === e.addressDetail.city) {
                        cityFound = true
                    }
                })
                if (!cityFound) {
                    cities.push({
                        label: e.addressDetail.city,
                        value: e.addressDetail.city
                    })
                }
            })
            setcities(cities)
            settableData(tableData)
            filterItems('all', tableData)
        }
    }
    
    const filterItems = ( key, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.addressDetail.city === key)
        setfilteredList(filteredList)
    }

    return {
        activeShownTotal,
        filteredList,
        activeCity,
        assignCity,
        cities,
    }
}