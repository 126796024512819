import { useCart } from './hooks'
import { useComponent, useElement, useWidget } from '../../../hooks'

export const Cart = () => {
    const Banner = useWidget('Banner')
    const Button = useElement('Button')
    const Img = useElement('Img')
    const Table = useComponent('Table')
    const {
        cartTotal,
        cartDataContent,
        cartTotalContent,
    } = useCart()

    return <>
        <Banner title='Cart' image="blue-road-1.jpg" />
        <div className="cart-page">
            <section className="jumbo-container">
                <div className="product-details">
                    {
                        cartTotal !== 0?
                        <div className="cart-form">
                            <Table
                                data={cartDataContent}
                            />
                        </div>:
                        <div className="empty-cart">
                            <Img source='internal' src={require('../../../../assets/images/emptycart.png')} alt="empty-cart" />
                            <p className="tac">No items in the cart yet!</p>
                            <Button className="btn-primary btn mauto mv20">Back to Shop</Button>
                        </div>
                    }
                </div>
                {
                    cartTotal !== 0 &&
                    <div className="calculate-cart-feild">
                        <div className="cart-total mlauto">
                            <div className="cart_totals">
                                <h4 className="box-header">Cart Totals</h4>
                                <div className="clearfix mt20">
                                    <Table
                                        data={cartTotalContent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </div>
    </>
}
