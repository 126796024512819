import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { NavigatorContext } from '../../../../context'

export const useGarageList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const {
        fetchAll,
        remove,
        update,
        save,
    } = useService('Data', 'garage')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        generateUID,
        showAlert,
        getFormattedDate,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const [tableContent, settableContent] = useState({})
    const [tableData, settableData] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState('')
    const [activeFilterIndex, setactiveFilterIndex] = useState(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState(false)
    const [trashAction, settrashAction] = useState(false)
    const [isMultipleDelete, setisMultipleDelete] = useState(false)
    const [checkedAll, setcheckedAll] = useState(false)
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    useEffect(() => {
        setTitle('Garages List', 'admin')
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getGarageListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getGarageListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            setfilteredList(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No garage added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No garage added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectedGarage = () => {
        deleteGarage(activeItem.uid)
    }
    
    const deleteGarage = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                getGarageListData()
                showAlert({ type: 'success', msg: 'Garage deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getGarageListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete garage!' })
        } else {
            let garageObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    garageObject = e
                }
            })
            garageObject.trash = task === 'trash'
            delete garageObject._id
            let data = {
                query: JSON.stringify({uid}),
                garageData: JSON.stringify(garageObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Garage '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getGarageListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' garage!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        if (checkedAll)
            setcheckedList(filteredList)
            filteredList.forEach(e => {
            deleteGarage(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const cloneGarage = async item => {
        let garageObject = { ...item, 
            slug: item.slug+'-copy',
            uid: generateUID()
        }
        const result = await save(garageObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Garage clonsed successfully!' })
            getGarageListData()
        } else showAlert({ type: 'error', msg: 'Unable to add garage!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            if (filterItems(item)) {
                let e = {
                    chaeckAction: <span onClick={() => toggleCheck(item.uid)}>
                        {
                            checkedAll || checkedList.includes(item.uid) ?
                            <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                        }
                    </span>,
                    title: item.title,
                    image: item.image?
                        <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                        <p>No Image</p>,
                    publishDate: getFormattedDate(new Date(item.publishDate)),
                    author: item.author,
                    status: <Badge item={item.status} />,
                    action: trashAction?
                        <div className="action-bar">
                            <Actor type='trash' onClick={() => {
                                setactiveItem(item)
                                setshowTrashConfirm(true)
                            }} />
                            <Actor type='undo' onClick={() => deleteGarage(item.uid, 'restore')} />
                        </div>:
                        <div className="action-bar">
                            <Actor type='trash' onClick={() => {
                                setactiveItem(item)
                                setshowTrashConfirm(true)
                            }} />
                            <Actor type='view' onClick={() => navigateTo({route: '/garage/'+item.slug, newTab: true})} />
                            <Actor type='edit' onClick={() => navigateTo({route: '/admin/garage/edit/'+item.uid})} />
                            <Actor type='copy' onClick={() => cloneGarage(item)} />
                        </div>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Author',
                    key: 'author'
                },
                {
                    label: 'Garage Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'action'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedGarage,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}