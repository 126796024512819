import { useEffect, useState } from "react"
import { config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useKeyListAdmin = () => {
    const Badge = useElement('Badge')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
        getFormattedDate,
        getFormattedTime,
    } = useService('Misc')
    const {getKeysList} = useService('Order')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState(commonPageSize)
    const [tableData, settableData] = useState([])
    const [searchKey, setsearchKey] = useState('')
    const [filteredList, setfilteredList] = useState([])

    useEffect(() => {
        setTitle('Car Keys List', 'admin')
        getCustomerKeysList()
    }, [])

    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getCustomerKeysList = async () => {
        const result = await getKeysList()
        if (result.status) {
            let tableData = result.data.reverse()
            settableData(tableData)
            setfilteredList(tableData)
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                registrationNo: item.registrationNo?item.registrationNo.toUpperCase(): '',
                supervisorId: item.supervisorId,
                timing: <>
                    {getFormattedDate(item.timing)} <br /> {getFormattedTime(item.timing)}
                </>,
                status: <Badge item={item.status} />
            }
            data.push(e)
        })
        return data
    }
    
    const search = e => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.registrationNo? e.registrationNo.toLowerCase(): ''
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }
    
    useEffect(() => {settableContent({
        headers: [
            {
                label: 'Registration No.',
                key: 'registrationNo'
            },
            {
                label: 'Supervisor Id.',
                key: 'superviseId'
            },
            {
                label: 'Timing',
                key: 'timing'
            },
            {
                label: 'Status',
                key: 'status'
            }
        ],
        content: getTableData(filteredList)
    })}, [filteredList])
    return {
        trackScrolling,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
    }
}